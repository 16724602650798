import './App.css';
import * as React from 'react'; 
import { MainForm } from './forms/MainForm.tsx';
import { DropForm } from './forms/DropForm.tsx';
import { Header } from './components/Header.tsx'
import * as Models from './Models.tsx';
import { openDatabase, upsertItem, getItem, deleteItem } from './indexedDBUtils.tsx';

/**
 * Appクラス 画面の大枠
 * @returns 
 */
export default function App() {
  
  //共通データ(訪問リスト、利用者リスト、職員リスト等の情報が入っている)
  const [commonData, setCommonData] = React.useState(null);

  //キャッシュデータ(GoogleMapsAPIからの結果のキャッシュデータが入っている)
  const [cacheData, setCacheData] = React.useState({
    googlemapsDirections :[],
  });

  //共通データの有無に合わせて画面を切り替える
  let content;
  if(commonData == null){
    //ファイルドロップ画面
    content = <DropForm setCommonData={setCommonData}/>;
  }else{
    //メイン画面(タイムラインや地図がある画面)
    content = <MainForm commonData={commonData} cacheData={cacheData}/>;
  }

  //初回レンダリング時にのみ実行
  React.useEffect(() => {
    // データベースを開く処理
    openDatabase()
      .then((database) => {

        //DB内のKeyがcommonDataのvalueをget
        getItem(database, "commonData")
          .then((result) => {
            if (result) {
              const storageData: Models.CommonData = JSON.parse(
                result,
                Models.reverser
              );
              setCommonData(storageData);
            }
          })
          .catch((error) => {
            console.log("Failed to get the Item (commonData) in App.tsx", error);
          });

        //DB内のKeyがcacheDataのvalueをget
        getItem(database, "cacheData")
          .then((result) => {
            if(result){
              const storageData: Models.CacheData = JSON.parse(
                result,
                Models.reverser
              );
              //古いキャッシュを削除
              const expiredDate = new Date();
              expiredDate.setDate(expiredDate.getDate() - 6);
              const newCacheData = {
                ...cacheData,
                googlemapsDirections: storageData.googlemapsDirections.filter(
                  (d) => d.savedDate >= expiredDate
                ),
              };
              setCacheData(newCacheData);
              upsertItem(database, "cacheData", JSON.stringify(newCacheData));
            }
            database.close();
          })
          .catch((error) => {
            console.log("Failed to get the Item (cacheData) in App.tsx", error);
          });
      })
      .catch((error) => {
        console.log("Database connection failed in App.tsx",error);
      });
  }, []);

  //キーイベント
  React.useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        // エスケープキー押下時
        if (
          confirm("ブラウザに保存されたデータを削除します。よろしいですか？")
        ) {
          openDatabase()
          .then(async (database) => {
            await deleteItem(database, 'cacheData');
            await deleteItem(database, 'commonData');
            location.reload();
          })
          .catch(() => {
            console.log("Failed to delete the Item in App.tsx");
          });
        }
      }
    };
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <div style={{display: 'flex', flexDirection: 'column', height:'100vh'}}>
      <Header commonData={commonData} setCommonData={setCommonData}></Header>
      {content}
    </div>
  );
}

//▼ Sample Code

//import logo from './logo.svg';
//import './App.css';

//function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
//}

//export default App;

