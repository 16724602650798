// データベースを開く
// 非同期処理を扱うため、Promiseを使用
export function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('first-nurse-DB', 1);

    // データベースのスキーマ変更やオブジェクトストアの作成時に呼ばれる
    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      // 'data'オブジェクトストアが存在しない場合に作成
      if (!db.objectStoreNames.contains("data")) {
        db.createObjectStore("data", { keyPath: 'key' });
      }
    };

    // データベースが正常に開かれた時に呼ばれる
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    // データベースの開放中にエラーが発生した場合
    request.onerror = () => {
      reject(request.error);
    };
  });
}

// データを追加または更新する
// `cacheData`はデータのキー、`jsonValue`はデータの値
// データが存在すれば更新、存在しなければ追加
export const upsertItem = (
  db: IDBDatabase,
  keyPath: string,
  jsonValue: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    // データベースが存在しない場合のエラーハンドリング
    if (db == null) {
      reject(new Error("Database does not exist!\nThis error was thrown by upsertItem()."));
      return;
    }

    const transaction = db.transaction("data", "readwrite");
    const store = transaction.objectStore("data");
    const item = { key: keyPath, value: jsonValue };

    // データを追加または更新
    const request = store.put(item);

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

// データを取得する
// `keyPath`は取得するデータのキー
// キーに対応するデータを文字列で返す
export const getItem = (db: IDBDatabase, keyPath: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    // データベースが存在しない場合のエラーハンドリング
    if (db == null) {
      reject(new Error("Database does not exist!\nThis error was thrown by getItem()."));
      return;
    }

    const transaction = db.transaction("data", "readonly");
    const store = transaction.objectStore("data");
    const request = store.get(keyPath);

    request.onsuccess = () => {
      const result = request.result;
      // データが存在する場合はその値を文字列として返す
      if (result !== undefined && result !== null) {
        resolve(String(result.value));
      } else {
        resolve(""); // データが存在しない場合は空文字列を返す
      }
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};

// データを削除する
// `keyPath`は削除するデータのキー
export const deleteItem = (db: IDBDatabase, keyPath: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    // データベースが存在しない場合のエラーハンドリング
    if (db == null) {
      reject(new Error("Database does not exist!\nThis error was thrown by deleteItem()."));
      return;
    }

    const transaction = db.transaction("data", "readwrite");
    const store = transaction.objectStore("data");
    const request = store.delete(keyPath);

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
};
