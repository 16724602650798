import './PlanTable.css';
import * as Models from '../Models.tsx';
import {Grid, Menu, MenuItem, Box} from '@mui/material';
import * as React from 'react';
import Draggable from 'react-draggable';
import {PublicParams, PublicCaches} from '../forms/MainForm.tsx';
import * as Const from '../Const.tsx';
import {CustomerDialog} from '../dialogs/CustomerDialog.tsx';
import {ScheduleDialog} from '../dialogs/ScheduleDialog.tsx';
import { CommonUtil } from '../CommonUtil.tsx';
import '../DateExtensions.tsx';
import {DraggableVisit} from './DraggableVisit.tsx';
import {StaffSchedule} from './StaffSchedule.tsx';

//右クリックの座標
export const Coordinate = React.createContext(null)

/**
 * 訪問計画テーブルコンポーネント
 * @returns 
 */
export function PlanTable(){
    //訪問計画(共通パラメータ)
    const commonData = React.useContext(PublicParams);

    //未割当訪問先リスト
    const initialUnassainedIds : number[] = commonData.visitList.filter( v => v.unassinedId != null).map( v => v.unassinedId);
    const [unassignedIds, setUnassignedIds] = React.useState(initialUnassainedIds);

    //複数同時移動中なら移動中のidの配列が入る(表示されているブロックが0番目)
    const [multipleDraggingIds, setMultipleDraggingIds] = React.useState<number[]>(null);
    
    //DraggableVisitに渡す用の関数
    //ドラッグしたときに共通パラメータのvisitListを更新するようにする
    const setVisit = function(visit: Models.Visit, index : number){
      //ドラッグしたvisitの移動本と移動先のコースの経路情報を削除
      commonData.setDirectionsList(commonData.directionsList.filter(x => 
        !((x.day == visit.day && x.staffCode == visit.staffCode) || (x.day == commonData.visitList[index].day && x.staffCode == commonData.visitList[index].staffCode))));
      
      //visitListをupdate
      commonData.setVisitList([
        ...commonData.visitList.slice(0, index),
        {...commonData.visitList[index], day:visit.day, staffCode:visit.staffCode, startTime:visit.startTime, 
          alert:visit.alert, warning:visit.warning, unassinedId:visit.unassinedId},
        ...commonData.visitList.slice(index + 1)]);
    }

    const setSchedule = function(schedule: Models.Schedule, index : number){
     
      //選択中のコースの経路情報を削除
      commonData.setDirectionsList(commonData.directionsList.filter(x => 
        !((x.day == schedule.day && x.staffCode == schedule.staffCode) || (x.day == commonData.scheduleList[index].day && x.staffCode == commonData.scheduleList[index].staffCode))));

      //scheduleListをupdate
      commonData.setScheduleList([
        ...commonData.scheduleList.slice(0, index),
        {...commonData.scheduleList[index], day:schedule.day, staffCode:schedule.staffCode, startTime:schedule.startTime, 
          endTime:schedule.endTime, content:schedule.content, other:schedule.other},
        ...commonData.scheduleList.slice(index + 1)]);
    }

    //planTableの範囲
    const divRef = React.useRef(null);
    const [contextMenu, setContextMenu] = React.useState<Models.ContextMenuPosition | null>(null);
    //右クリックイベント
    function handleRightClick(event) {
      event.preventDefault(); 
      // マウスイベントからplanTableの位置を取得
      const planTableRect = divRef.current.getBoundingClientRect();
      //座標を計算し、planTableの範囲内に収める
      const X = event.clientX - planTableRect.left;
      const Y = event.clientY - planTableRect.top;
      setContextMenu({mouseX: X,mouseY: Y,})
    }
    //警告を検査(初回のみ)
    React.useEffect(()=>{
      for(let idx=0; idx<commonData.visitList.length; idx++){
        const visit = commonData.visitList[idx];
        const customer = commonData.customerList.find( c => c.code === visit.customerCode);
        if(customer != null){
          const alertSet: Models.AlertSet = detectAlert({visit: visit, customer: customer}, commonData);
          //警告をセット
          visit.warning = alertSet.warningList;
          visit.alert = alertSet.alertList;
          //visitListをupdate
          commonData.setVisitList([
            ...commonData.visitList.slice(0, idx),
            {...commonData.visitList[idx], alert:visit.alert, warning:visit.warning},
            ...commonData.visitList.slice(idx + 1)
          ]);
        }
      }
    },[]);

    // 訪問情報(visit)からブロックの位置を計算(スケジュールにも使用可)
    const getCoordinate = (visit)=>{

      const rowHeight = Const.LAYOUT.TIMELINE_CELL_HEIGHT; //セル1マス分の高さ
      const colWidth = Const.LAYOUT.TIMELINE_CELL_WIDTH;   //セル1マス分の幅
      const dayBorder = 1;
      const startDay = 0; //月曜始まり
      const startHour = Const.TIMELINE_START_HOUR; //タイムライン開始時刻(例 8時)
      const endHour = Const.TIMELINE_END_HOUR;    //タイムライン終了時刻(例 18時)
      const dayWidth = colWidth * commonData.staffList.length + dayBorder; //1日分の幅
      const dayCol = visit.day; //何曜日か(月曜が0)
      const staffCol = commonData.staffList.findIndex((s) => s.code == visit.staffCode); //左から何人目の職員か(0始まり)

      //X座標(月曜startHourの左上が起点)
      let X;
      if(visit.unassinedId === undefined || visit.unassinedId === null){
        X = (dayCol - startDay) * dayWidth + staffCol * colWidth;
      }else{
         X = Const.LAYOUT.UNASSIGNED_LEFT + (visit.unassinedId - 1) * colWidth; //未割当の場合
      }

      //Y座標(月曜startHourの左上が起点)
      let Y;
      if(visit.unassinedId === undefined || visit.unassinedId === null){
        //未割当以外の場合
        let hours = visit.startTime.getHours();
        let minutes = visit.startTime.getMinutes();
        if(hours < startHour || endHour <= hours){ //範囲外はstartHourとして扱う
          hours= startHour;
          minutes = 0;
        }
        const timeRow = (hours - startHour) * 4 + minutes / 15; //上から何行目か(0始まり)
        Y = timeRow * rowHeight;
      }else{
          //未割当の場合
        Y = -Const.DRAGGABLE_VISIT_LAYER_TOP + 2; 
      }
      return { x: X, y: Y };  
    }


    return (
      <div id="planTableWrapper">
        <table id="planTable" ref={divRef} onContextMenu={handleRightClick}>
          <tbody>
            <tr>
              <td className="nowrap" colSpan={6} style={{ borderBottom:'1px solid #888', height:`${Const.LAYOUT.UNASSIGNED_HEIGHT}px`}}>
              <span>未割当</span>
              </td>
            </tr>
            <tr>
              <td className="nowrap"><DayColumn day={0}></DayColumn></td>
              <td className="nowrap"><DayColumn day={1}></DayColumn></td>
              <td className="nowrap"><DayColumn day={2}></DayColumn></td>
              <td className="nowrap"><DayColumn day={3}></DayColumn></td>
              <td className="nowrap"><DayColumn day={4}></DayColumn></td>
              <td className="nowrap"><DayColumn day={5}></DayColumn></td>
            </tr>
          </tbody>
        </table>
        {commonData.dialogName === Const.DialogNames.CUSTOMER_DIALOG && (
            <CustomerDialog isAddDialog={false}/>
        )}
        {commonData.dialogName === Const.DialogNames.ADD_CUSTOMER_DIALOG && (
          <Coordinate.Provider value={contextMenu}>
            <CustomerDialog isAddDialog={true}/>
          </Coordinate.Provider>
        )}
        {commonData.dialogName === Const.DialogNames.SCHEDULE_DIALOG && (
            <ScheduleDialog isAddDialog={false}/>
        )}
        {commonData.dialogName === Const.DialogNames.ADD_SCHEDULE_DIALOG && (
          <Coordinate.Provider value={contextMenu}>
            <ScheduleDialog isAddDialog={true}/>
          </Coordinate.Provider>
        )}
        <Box id="draggableVisitLayer" sx={{position: "absolute", top: `${Const.DRAGGABLE_VISIT_LAYER_TOP}px`}}>
          {commonData.scheduleList && commonData.scheduleList.map((schedule, i) => (
            <StaffSchedule 
              key={schedule.id}
              schedule={schedule}
              setSchedule={setSchedule}
              index={i}
              position={getCoordinate(schedule)} >
            </StaffSchedule>
          ))}
          {commonData.visitList.map((visit, i) => (
            <DraggableVisit 
              visit={visit}
              setVisit={setVisit}
              customer={commonData.customerList.find( c => c.code === visit.customerCode)}
              unassignedIds={initialUnassainedIds}
              setUnassignedIds={setUnassignedIds}
              index={i}
              key={visit.id}
              position={getCoordinate(visit)}
              multipleDraggingIds={multipleDraggingIds}
              setMultipleDraggingIds={setMultipleDraggingIds}>
            </DraggableVisit>
          ))}
        </Box>
      </div>
    )
  }

/**
 * 1日列コンポーネント(曜日,警告メッセージ,複数のスタッフを含む)
 * @param props day:曜日(0=月,1=火,…)
 * @returns 
 */
function DayColumn(props : {day : number}){
  //共通データ
  const commonData = React.useContext(PublicParams);

  //警告表示用
  const [alertView, setAlertView] = React.useState<React.JSX.Element[]>();
  const [warinngView, setWarinngView] = React.useState<React.JSX.Element[]>();

  React.useEffect(()=>{
    //該当日の訪問データを抽出
    const dayVisitList = commonData.visitList.filter((v)=> v.day === props.day);

    //※曜日エリアに表示する警告はwaning,alert各1種類しかないが、将来性を考えてListにする

    //警告リスト(warning)
    const warningList:Const.WarningMessageTypes[] = [];
    dayVisitList.forEach((p)=>{
      const targetedWarning = p.warning.filter(w=> w == Const.WarningMessages.SAME_LOCATION);
      warningList.push(...targetedWarning);
    })

    //警告リスト(alert)
    const alertList:Const.AlertMessageTypes[] = [];
    dayVisitList.forEach((p)=>{
      const targetedAlert = p.alert.filter(a=> a == Const.AlertMessages.OVERLAP);
      alertList.push(...targetedAlert);
    })

    //警告リストを元に表示用のGridを生成
    setAlertView([...new Set(alertList)].map((msg, idx) => (
      <Grid item key={idx} style={{marginLeft:`${Const.LAYOUT.DAY_ALERT_MARGIN_LEFT}px`}} className="alert">{msg}</Grid>
    )));
    setWarinngView([...new Set(warningList)].map((msg, idx) => (
      <Grid item key={idx} style={{marginLeft:`${Const.LAYOUT.DAY_ALERT_MARGIN_LEFT}px`}} className="warning">{msg}</Grid>
    )));
    
  },[commonData.visitList])

  return(
    <div style={{borderRight:'1px solid #888'}}>
      <Grid container className="header-color" style={{borderBottom:'1px solid #888', height:`${Const.LAYOUT.DAY_HEDDER_HEIGHT}px`}}>
        <Grid item >{Const.DAY_NAME_LIST[props.day]}</Grid>
        {alertView}
        {warinngView}
      </Grid>
      
      <div style={{display: 'flex'}}>
        <StaffHeaderColumn></StaffHeaderColumn>
        {commonData.staffList.map((staff, i) => (
          <StaffColumn day={props.day} staff={staff} key={i}></StaffColumn>
        ))}
      </div>


    </div>
  )
  
}

/**
 * スタッフ隠し列コンポーネント(各曜日の左端にある隠し列。1時間ごとに薄い字で時刻を表示する)
 * @returns 
 */
function StaffHeaderColumn(){
  //stateが変更されるたびに再レンダリングされるのを防ぐ
  const TimeLineMemo = React.memo(TimeLine);

  return(
    <Grid item container width="0" borderRight="" direction="column" >
      <Grid item style={{height:`${Const.LAYOUT.STAFF_NAME_GRID_HEIGHT}px`}}></Grid>
      <Grid item container style={{height:`${Const.LAYOUT.STAFF_ALERT_GRID_HEIGHT}px`}} direction="column" ></Grid>
      <TimeLineMemo isHeaderColumn={true}></TimeLineMemo>
    </Grid>
  )
}

/**
 * スタッフ列コンポーネント(スタッフ名,警告メッセージ,タイムラインの枠が含まれる)
 * @param props day:曜日 staff:職員 key:一意キー(なくても動くけど、つけることでパフォーマンスが向上する)
 * @returns 
 */
function StaffColumn(props : {day : number, staff : Models.Staff, key : number}){
  //共通データ
  const commonData = React.useContext(PublicParams);

  //訪問先数(スタッフ名の横に表示)
  const [visitCount, setVisitCount] = React.useState(0);
 
  React.useEffect(()=>{
    //該当日、該当スタッフの訪問データの個数を取得
    const count = commonData.visitList.filter((v)=> v.day === props.day && v.staffCode === props.staff.code).length;
    //visitCountにセット
    setVisitCount(count);
  },[commonData.visitList]);

  /////////////////////////////////////////////
  // スタッフ情報セル
  /////////////////////////////////////////////
  const visitCountElement = React.useRef<HTMLElement | null>(null);
  React.useEffect(()=>{
    //スタッフ名を表示。人数は小さく表示。人数に応じて色が変わる
    if(visitCountElement.current != null){
      visitCountElement.current.className = (visitCount >= Const.COUNT_WARNING_NUM ? 'count-warning' : '');
      visitCountElement.current.innerHTML = String(visitCount);
    }
  },[props.staff, visitCount]);

  /////////////////////////////////////////////
  // ヘッダ行クリック
  /////////////////////////////////////////////
  const activateStaff = ()=>{
    commonData.setActiveVisitIds([{type:Const.ActiveBlockTypes.STAFF, id:props.staff.code}]);
  }

  /////////////////////////////////////////////
  // タイムライン右クリックメニュー
  /////////////////////////////////////////////
  const [contextMenu, setContextMenu] = React.useState<Models.ContextMenuPosition | null>(null);
  const handleTimeLineMenuOpen = (e) => {
    e.preventDefault();
    if(contextMenu === null){
      setContextMenu({
        mouseX: e.clientX + 2,
        mouseY: e.clientY - 6,
      });
    }else{
      setContextMenu(null);
    }
  }
  const handleTimeLineMenuClose = () => {
    setContextMenu(null);
  };

  const handleOpenAddCustomerDialog = () => {
    commonData.setDialogName(Const.DialogNames.ADD_CUSTOMER_DIALOG);
    handleTimeLineMenuClose();
  }; 

  const handleOpenAddScheduleDialog = () => {
    commonData.setDialogName(Const.DialogNames.ADD_SCHEDULE_DIALOG);
    commonData.setActiveVisitIds([]);
    handleTimeLineMenuClose();
  }; 
  
  /////////////////////////////////////////////
  // パフォーマンス対策
  /////////////////////////////////////////////

  //stateが変更されるたびに再レンダリングされるのを防ぐ
  const TimeLineMemo = React.memo(TimeLine);
    
  return(
    <Grid item container sx={{width:`${Const.LAYOUT.TIMELINE_CELL_WIDTH}px`}} borderRight={"1px solid #888"} direction="column" >
      <div onClick={activateStaff}>
        {/* スタッフ名+訪問人数 */}
        <Grid item sx={{height:`${Const.LAYOUT.STAFF_NAME_GRID_HEIGHT}px`}} className="header-color">
          <span>{props.staff.name.split(' ')[0]}</span>
          <span ref={visitCountElement} style={{fontSize:'x-small'}}></span>
        </Grid>
        {/* 警告エリア */}
        <Grid item container style={{height:`${Const.LAYOUT.STAFF_ALERT_GRID_HEIGHT}px`}} className="header-color" borderBottom="1px solid #888" direction="column" >
          <StaffNotice day={props.day} staff={props.staff}></StaffNotice>
        </Grid>
      </div>
      <div onContextMenu={handleTimeLineMenuOpen}>
        {/* タイムライン */}
        <TimeLineMemo isHeaderColumn={false}></TimeLineMemo>
        <Menu
          open={contextMenu !== null}
          onClose={handleTimeLineMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleOpenAddCustomerDialog}>利用者追加</MenuItem>
          <MenuItem onClick={handleOpenAddScheduleDialog}>スケジュール追加</MenuItem>
        </Menu>
      </div>
    </Grid>
  )
}

/**
 * スタッフ名下の警告表示領域のコンポーネント
 * @param props day:曜日 staff:職員
 * @returns 
 */
function StaffNotice(props : {day : number, staff : Models.Staff}){
  //共通データ
  const commonData = React.useContext(PublicParams);

  //警告表示用
  const [alertView, setAlertView] = React.useState<React.JSX.Element[]>();
  const [warinngView, setWarinngView] = React.useState<React.JSX.Element[]>();

  React.useEffect(()=>{
    //該当日、該当スタッフの訪問データを抽出
    const staffVisitList = commonData.visitList.filter((v)=> v.day === props.day && v.staffCode === props.staff.code);

    //warning
    const warinngList:Const.WarningMessageTypes[] = [];
    staffVisitList.forEach((p)=>{
      const targetedWarning = p.warning.filter(w=> w != Const.WarningMessages.SAME_LOCATION);
      warinngList.push(...targetedWarning);
    })

    //alert
    const alertList:Const.AlertMessageTypes[] = [];
    staffVisitList.forEach((p)=>{
      const targetedAlert = p.alert.filter(w=> w != Const.AlertMessages.OVERLAP);
      alertList.push(...targetedAlert);
    })

    setAlertView([...new Set(alertList)].map((alertWord, idx) => (<Grid className="alert" key={idx} item>{alertWord}</Grid>)));
    setWarinngView([...new Set(warinngList)].map((warningWord, idx) => (<Grid className="warning" key={idx} item>{warningWord}</Grid>)));

  },[commonData.visitList])

  return(
    <Grid >
      {alertView}
      {warinngView}
    </Grid>
  )
}

/**
 * タイムライン(1人分)のコンポーネント
 * @param props isHedearColumn:左端の不可視列の場合はtrue
 * @returns 
 */
function TimeLine(props : {isHeaderColumn : boolean}){
  const minutes = [0,15,30,45];

  //時間配列を作る
  //(例) const hours = [8,9,10,11,12,13,14,15,16,17,18];
  const hours=[];
  let h = Const.TIMELINE_START_HOUR;
  while(h < Const.TIMELINE_END_HOUR){
    hours.push(h);
    h++;
  }

  return(
    <Grid item xs="auto" container direction="column">
      {hours.map((h) => (
        minutes.map((m) => (
          <TimeLineCell hour={h} minute={m} isHeaderColumn={props.isHeaderColumn} key={h + "-" + m}></TimeLineCell>
        ))
      ))}
    </Grid>
  )
  
}

/**
 * タイムラインのセル(1マス分)のコンポーネント
 * @param props hour:時 minute:分 isHedearColumn:左端の不可視列の場合はtrue key:一意キー(なくても動くけど、つけることでパフォーマンスが向上する)
 * @returns 
 */
function TimeLineCell(props : {hour : number, minute : number, isHeaderColumn: boolean, key : string}){
  if(props.isHeaderColumn){
    return(
      //sx={{position: "absolute", top: `${Const.DRAGGABLE_VISIT_LAYER_TOP}px`}
      <Grid item className="timeline-heder-column-cell" sx={{position : 'relative', height:`${Const.LAYOUT.TIMELINE_CELL_HEIGHT}px`}}>
        {props.minute === 0 ? <div className="backStr">{props.hour + ":00"}</div> : ""}
      </Grid>
    )
  }else{
    return(
      <Grid item className="timeline-cell" sx={{height:`${Const.LAYOUT.TIMELINE_CELL_HEIGHT}px`}}>
      </Grid>
    ) 
  }
}

/**
 * 対象の訪問先ブロックの警告(アラート&ワーニング)を調べる。警告に該当すれば、それを返す
 * @param props visit:対象の訪問先 customer:利用者情報
 * @returns 警告リスト
 */
export function detectAlert( props : {visit:Models.Visit, customer:Models.Customer}, commonData:Models.CommonData):Models.AlertSet {
  const alertList:Const.AlertMessageTypes[]=[];
  const warningList:Const.WarningMessageTypes[]=[];
  const dependencyVisitList: Models.Visit[] =[];

  if(props.visit.unassinedId === null || props.visit.unassinedId <= 0){
    //担当スタッフの基本情報を取得
    const myStaffs = commonData.staffList.filter((s) => s.code === props.visit.staffCode);
    const myStaff = myStaffs.length > 0 ? myStaffs[0] : null;

    ////////////////////////////
    // × 警告(アラートレベル)
    ////////////////////////////

    //同一日に同じ利用者を複数回訪問していないか？
    if(myStaff != null){
      const duplicationVisit = commonData.visitList.filter( v => v.day == props.visit.day 
        && v.customerCode == props.visit.customerCode 
        && (v.unassinedId === null || v.unassinedId <= 0)
        && v.partnerVisitId != props.visit.id);
      if(duplicationVisit.length > 1){
        alertList.push(Const.AlertMessages.OVERLAP); //「重複訪問」を追加
        dependencyVisitList.push(...duplicationVisit);
        dependencyVisitList.push(commonData.visitList.find(v => v.partnerVisitId != props.visit.id));
      }
    }

    //2人訪問の時、メイン担当者がいるか？
    if(props.visit.partnerVisitId !== null && !props.visit.isPrimaryStaff){
      const partner = commonData.visitList.find( v => v.id == props.visit.partnerVisitId);
      if(partner && !partner.isPrimaryStaff){
        alertList.push(Const.AlertMessages.NO_PRIMARY_STAFF); //「メイン無」を追加
        dependencyVisitList.push(partner);
      }
    }

    //資格
    if(myStaff != null){
      let isLicenseOK = true;
      switch(myStaff.license){
        case 1:
          //正看護師
          break;
        case 2:
          //准看護師
          if(props.visit.isPrimaryStaff){
            isLicenseOK = false; //准看護師はメイン担当者になれない
          }
          break;
        default:
          //無資格・その他
          if(props.visit.isPrimaryStaff || props.visit.partnerVisitId == null){
            isLicenseOK = false; //無資格者は1人では訪問不可
          }
          break;
      }
      if(isLicenseOK === false){ 
        alertList.push(Const.AlertMessages.LICSENSE_VIOLATION); //「資格違反」を追加
      }
    }

    //希望人数が「2人」にも関わらず1人で訪問していないか
    if(props.customer != null && 100 <= props.customer.requireTwoStaffs){
      if(props.visit.partnerVisitId == null){
        alertList.push(Const.AlertMessages.TWO_STAFFS_UNDESIRED);
      }
    }
    
    ////////////////////////////
    // △ 警告(ワーニングレベル)
    ////////////////////////////

    //同一建物(同一座標)の訪問先が規定の件数以上あるか？
    const sameLocationCustCodes = commonData.customerList.filter( c => c.lat == props.customer.lat && c.lng == props.customer.lng).map( c => c.code);
    if(sameLocationCustCodes.length > 1){
      //同一日同一座標の訪問先
      const sameLocationVisits= commonData.visitList.filter( v => v.day == props.visit.day 
        && (v.unassinedId == null || v.unassinedId <= 0)
        && sameLocationCustCodes.includes(v.customerCode));
      //2人訪問等でcustomerCodeが重複しているものを除く
      const sameLocationVisitsDistinct = [...new Set(sameLocationVisits.map(v=>v.customerCode))];
      if(sameLocationVisitsDistinct.length >= Const.SAME_LOCATION_CHECK_NUM){
        warningList.push(Const.WarningMessages.SAME_LOCATION); //「同一建物」を追加
        dependencyVisitList.push(...sameLocationVisits);
      }
    }

    //希望人数が「2人」にも関わらず1人で訪問していないか
    if(props.customer != null && 0 < props.customer.requireTwoStaffs && props.customer.requireTwoStaffs < 100){
      if(props.visit.partnerVisitId == null){
        warningList.push(Const.WarningMessages.TWO_STAFFS_UNDESIRED); //「希望人数」を追加
      }
    }

    //希望時刻
    if(props.customer.specifiedTimes){
      const requireStart = props.customer.requireTime[props.visit.day].startTime;
      const requireEnd = props.customer.requireTime[props.visit.day].endTime;
      if(requireStart == null && requireEnd == null){
          warningList.push(Const.WarningMessages.TIME_UNDESIRED); //「希望時刻」を追加
      }
      if((requireStart != null && props.visit.startTime < requireStart)
        || (requireEnd != null && requireEnd < props.visit.startTime)){
          warningList.push(Const.WarningMessages.TIME_UNDESIRED); //「希望時刻」を追加
      }
    }      

    //性別希望
    if(myStaff != null){
      const isSatisfiedSex = [0, myStaff.sex].includes(props.customer.requireSex);
      if(isSatisfiedSex === false){ 
        warningList.push(Const.WarningMessages.SEX_UNDESIRED); //「性別」を追加
      }
    }

    //勤務時刻
    if(myStaff != null){
      const workStart = myStaff.workTime[props.visit.day].startTime;
      const workEnd = myStaff.workTime[props.visit.day].endTime;
      if((workStart != null && props.visit.startTime < workStart)
        || (workEnd != null && workEnd < props.visit.endTime)){
          warningList.push(Const.WarningMessages.OUT_OF_WORK_TIME);
      }
    }
  }

  //重複除外
  const dependencyVisitList_ = [...new Set(dependencyVisitList)];

  return {alertList: alertList, warningList: warningList, dependencyVisitList: dependencyVisitList_}
}

/**
 * 配列の数の中で、startNumber以上かつ欠番になっている数のうち最小のものを求める
 * @param numArray 
 * @param startNumber 
 */
export function getMissingNumber(numArray : number[], startNumber : number){
  //配列を昇順に並び替える
  const sortedArray = numArray.concat();
  sortedArray.sort((x,y) => (x - y));

  //startNumberが最小欠番のとき
  if(sortedArray.length == 0 || startNumber < sortedArray[0]){
    return startNumber;
  }

  //1つずつ欠番がないかチェックしていく
  let currentNumber = sortedArray[0];
  for(let i = 1; i<sortedArray.length; i++){
    if(sortedArray[i] - currentNumber <= 1){
      //欠番ではない
      currentNumber = sortedArray[i];
    }else{
      //欠番発見
      return currentNumber + 1;
    }
  }

  //欠番がない場合、配列最後の数値+1
  return currentNumber + 1;
}