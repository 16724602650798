import * as Models from "../Models.tsx";
import Excel from "exceljs";
import * as ExcelConst from '../ExcelConst.tsx';
import * as Const from '../Const.tsx';
import { CommonUtil } from '../CommonUtil.tsx';

/**
 * Excelファイルのデータ取得
 * @param file 
 * @returns 
 */
export function readExcelFile(file) :Promise<{staffList:Models.Staff[], customerList:Models.Customer[], visitList:Models.Visit[] ,scheduleList:Models.Schedule[]}>{
  return new Promise((resolve, reject) => {
    //ファイル読み込み
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = new Excel.Workbook();
      workbook.xlsx.load(file).then(() => {
        try {
          const staffList = getStaffList(workbook);
          const customerList = getCustomerList(workbook);
          const visitList = getVisitList(workbook, customerList, staffList).list;
          const scheduleList = getVisitList(workbook, customerList, staffList).scheduleList;

          //タイムラインシートで訪問している回数と、利用者情報シートの訪問頻度が一致しているか確認
          for(let i=0; i< customerList.length; i++){
            const customerCode = customerList[i].code;
            const targetVisit = visitList
            .map((v) => ({ id: v.id , customerCode: v.customerCode, partnerVisitId: v.partnerVisitId }))
            .filter( v => v.customerCode === customerCode); 
            const visitCount = CommonUtil.countDuplicatePairs(targetVisit);
            if(visitCount !== customerList[i].visitPerWeek && customerCode !== -1){
              alert('利用者情報シートの利用者の訪問頻度と、タイムラインシートで訪問している回数が一致していません。(利用者コード:' + customerCode + ')');
              return;
            }
          }

          resolve({ staffList, customerList, visitList, scheduleList});
      } catch (error) {
          alert(error);
          return;
      }
      });
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 * タイムラインシートのデータ取得
 * @param workbook 
 * @param customerList 
 * @param staffList 
 * @returns 
 */
function getVisitList(workbook:Excel.Workbook,customerList: Models.Customer[], staffList: Models.Staff[]) {
  const visitList: Models.Visit[] = [];
  const scheduleList: Models.Schedule[] = [];
  let week = -1;
  let id = 1;
  let scheduleId = 1;
  let errorMessage;
  let previousDateCell = null; 
  //タイムラインのシートを取得
  const sheet = workbook.getWorksheet(workbook.worksheets[0].name);
  for (let column = ExcelConst.TIMELINE_SHEET_START_COLUMN ; column <= sheet.actualColumnCount; column++) {
    const dateRow = sheet.getRow(1);
    const dateCell = dateRow.getCell(column).text;

    if (dateCell !== previousDateCell && ! Const.DAY_NAME_LIST.includes(dateCell)) {
      week ++;
    }
    previousDateCell = dateCell;

    for (let row = ExcelConst.TIMELINE_SHEET_START_ROW ; row <= sheet.rowCount - 1; row++) {
      const timeCell = sheet.getCell(row, column);
      const customerNameCellValue = sheet.getCell(row + 1, column);
      const timeCellValue = timeCell.text;
      let isSchedule = false;
      if (!timeCell.value) {
        continue;
      }
      if(timeCellValue.split("\n").length > 2 && !timeCellValue.includes('ID')){
        isSchedule = true;
      }
      if(!customerNameCellValue.text && !isSchedule){
        throw new Error(errorMessage ='利用者の時刻・名前が取得できません。' + '(' + sheet.name + 'シート)'+ customerNameCellValue.address)
      }

      //スタッフコード
      const timeCellAddress = timeCell.address;
      const staffNameAdress = timeCellAddress.replace(/[^a-zA-Z]/g, '') + "3";
      const staffNameCellValue = sheet.getCell(staffNameAdress).text;
      const staffCode = parseInt(staffNameCellValue.replace(/[^0-9]/g, '')); 
      const staff = staffList.find((s) => s.code === staffCode);
      if(!staff && !isSchedule){
        throw new Error(errorMessage = 'スタッフ情報シートに存在しない社員名があります。' + '(' + sheet.name + 'シート)')
      }

      //訪問時間
      const time = timeCellValue.split("\n");
      const startTime = new Date(ExcelConst.DATE + time[0]);
      const endTime = new Date(ExcelConst.DATE + time[1]);
      if(isNaN(startTime.getTime()) || isNaN(endTime.getTime())){
        throw new Error(errorMessage = '時刻が読み取れません。' + '(' + sheet.name + 'シート,' + timeCellAddress + ')')
      }

      //利用者コード
      const customerCode = parseInt(customerNameCellValue.text.substring(customerNameCellValue.text.indexOf('ID') + 2));
      const customer = customerList.find((c) => c.code === customerCode);
      if(!customer && !isSchedule){
        throw new Error(errorMessage = '利用者情報シートに存在しない利用者名があります。' + '(' + sheet.name + 'シート)' + customerNameCellValue.address)
      }

      //2人訪問のとき、相方のVisitのidを取得
      let partnerVisitId = null;
      const partnerVisit = visitList.filter((v) => v.customerCode === customerCode && v.day === week);
      partnerVisit.forEach((partnerVisit) => {
        partnerVisitId = partnerVisit.id
        partnerVisit.partnerVisitId = id;
      });
      if (partnerVisit.length >= 2 && !isSchedule) {
        throw new Error(errorMessage = '同じ日の同じ利用者が3つ以上あります。' + '(' + sheet.name + 'シート)')
      }

      
      let isGeneralNursing = false;
      let isPrimaryStaff = false;
      let isTimeHighlighted = false;

      if(!isSchedule){

        //一般訪問看護
        const customerNameFontItalic =
        (customerNameCellValue.value as Excel.CellRichTextValue)?.richText[0]?.font?.italic ||
        customerNameCellValue.font?.italic;
        if (customerNameFontItalic) {
          isGeneralNursing = true;
        }

        //2人訪問のメイン担当
        const customerNameFontUnderline =
        (customerNameCellValue.value as Excel.CellRichTextValue)?.richText[0]?.font?.underline ||
          customerNameCellValue.font?.underline;
        if (customerNameFontUnderline && !isSchedule) {
          isPrimaryStaff = true;
        }
        if (isPrimaryStaff) {
          const partnerVisit = visitList.find(v => v.partnerVisitId === id);
          if (partnerVisit?.isPrimaryStaff) {
            isPrimaryStaff = false;
          }
        }

        //時刻を強調
        if (timeCell.font.bold) {
          isTimeHighlighted = true;
        }
      }
      
      if(isSchedule){
        //スケジュールリストに追加
        const schedule: Models.Schedule = {
          id: scheduleId++,
          day: week,
          staffCode: staffCode,
          arrivalTime: null,
          startTime: startTime,
          endTime: endTime,
          content: time[2],
          other: null,
        };
        scheduleList.push(schedule);
        //終了時刻と開始時刻の差分
        const diffTime = endTime.setFullYear(2023, 0, 1) - startTime.setFullYear(2023, 0, 1);
        const diffHour = (diffTime / (1000 * 60 * 60)) * 60;
        const diff = (diffHour / 15) - 1;
        row += diff
      }else{
        const visit: Models.Visit = {
          id: id++,
          customerCode: customerCode,
          day: week,
          staffCode: staffCode,
          arrivalTime: null,
          startTime: startTime,
          endTime: endTime,
          warning: [],
          alert: [],
          partnerVisitId: partnerVisitId,
          unassinedId: null,
          isPrimaryStaff: isPrimaryStaff, 
          isGeneralNursing: isGeneralNursing,
          isTimeHighlighted: isTimeHighlighted
        };
        visitList.push(visit);
        row++;
      }
    }
  }
  //未割当の利用者のリストを追加
  const unassignedList = getUnassignedList(workbook, id);
  const list = visitList.concat(unassignedList);
  return {list, scheduleList};
}

/**
 * 未割当の利用者
 * @param workbook 
 * @param id 
 * @returns 
 */
function getUnassignedList(workbook: Excel.Workbook, id: number) {
  const unassignedList: Models.Visit[] = [];
  let unassinedCount = 1;
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.UNASSINGED);
  for (let i = ExcelConst.UNASSIGNED_SHEET_START_ROW;i < sheet.actualRowCount + 1 ; i++) {
    const row = sheet.getRow(i);
    const customerCode = parseCellToInt(sheet,row.getCell(1), '利用者コードが数値で入力されていません。');

    const unassigned: Models.Visit = {
      id: id++,
      customerCode: customerCode,
      day: -1,
      staffCode: -1,
      arrivalTime: null,
      startTime: null,
      endTime: null,
      warning: [],
      alert: [],
      partnerVisitId: null,
      unassinedId: unassinedCount++,
      isPrimaryStaff: false,
      isGeneralNursing: false,
      isTimeHighlighted: false
    };
    unassignedList.push(unassigned);
  }
  return unassignedList;
}

/**
 * 利用者情報取得
 * @param workbook 
 * @returns 
 */
function getCustomerList(workbook: Excel.Workbook) {
  const customerList: Models.Customer[] = [];
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.CUSTOMER);

  for (let i = ExcelConst.CUSTOMER_SHEET_START_ROW;i < sheet.actualRowCount + 1;i++) {
    const row = sheet.getRow(i);

    //「休止」などの非数値が入っていた場合は、訪問頻度を0にする
    const visitPerWeekCell = row.getCell(8);
    let visitPerWeek;
    if (!isNaN(parseInt(visitPerWeekCell.text))) {
      visitPerWeek = parseInt(visitPerWeekCell.text);
    }else if(!visitPerWeekCell.value){
      visitPerWeek = null;
    }else {
      visitPerWeek = 0;
    }

    const code = parseCellToInt(sheet, row.getCell(1), '利用者コードが数値で入力されていません。');
    const matchLevel = parseCellToInt(sheet, row.getCell(4), '住所精度が数値で入力されていません。');
    const requireTwoStaffs = parseCellToInt(sheet,row.getCell(9), '希望人数が数値で入力されていません。');
    const requireSex = parseCellToInt(sheet,row.getCell(11), '希望職員性別が数値で入力されていません。');
    const isSpecifiedTimes = parseCellToInt(sheet,row.getCell(13), '曜日・時刻希望が数値で入力されていません。');    

    //利用者のセルの色を取得
    let color;
    const cellColor = row.getCell(2).fill;
    if(code == -1){
      color = [255,255,255];
    }else if(cellColor && cellColor.type === 'pattern' && cellColor.fgColor != null){
      color = CommonUtil.argbToRgb(cellColor.fgColor.argb);
    }else{
      color = [255,255,255];
    }

    const customer: Models.Customer = {
      code: code,
      name: row.getCell(2).text,
      address : row.getCell(3).text,
      matchLevel : matchLevel,
      matchLevelStr : row.getCell(5).text,
      lat: parseFloat(row.getCell(6).text),
      lng: parseFloat(row.getCell(7).text),
      color : color,
      visitPerWeek: visitPerWeek,
      requireTwoStaffs: requireTwoStaffs,
      requireSex: requireSex,
      specifiedTimes : isSpecifiedTimes,
      requireTime: getTimeFromSheet(sheet, i, 15),
      others: row.getCell(27).text,
    };
    customerList.push(customer);
  }
  return customerList;
}

/**
 * スタッフ情報取得
 * @param workbook 
 * @returns 
 */
function getStaffList(workbook: Excel.Workbook) {
  const staffList: Models.Staff[] = [];
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.STAFF);

  for (let i = ExcelConst.STAFF_SHEET_START_ROW;i < sheet.actualRowCount + 1;i++) {
    const row = sheet.getRow(i);
    const code = parseCellToInt(sheet,row.getCell(1), '社員コードが数値で入力されていません。');
    const sex = parseCellToInt(sheet,row.getCell(3), '性別が数値で入力されていません。');
    const license = parseCellToInt(sheet,row.getCell(5), '資格が数値で入力されていません。');

    const staff: Models.Staff = {
      code: code,
      // eslint-disable-next-line no-irregular-whitespace
      name: row.getCell(2).text.replace(/　/g," "),
      sex: sex,
      license: license,
      workTime: getTimeFromSheet(sheet, i, 7),
    };
    staffList.push(staff);
  }
  return staffList;
}

/**
 * 利用者の希望時間、勤務時間の取得
 * @param sheet 
 * @param i 
 * @param num 
 * @returns 
 */
const getTimeFromSheet = (sheet: Excel.Worksheet, i: number, num: number) => {
  const row = sheet.getRow(i);
  const staffWorkTime: Models.TimeWindow[] = [];

  //取得した時刻を正しい形に変換
  const convertDate = (cell: Excel.Cell): Date | null => {
    if (cell.type === Excel.ValueType.Date) {
      return new Date((cell.value as Date).setHours((cell.value as Date).getHours() - 9));
    } else if (cell.type === Excel.ValueType.String) {
      return new Date(ExcelConst.DATE + cell.value);
    } else {
      return null;
    }
  };

  for (let colIdx = num; colIdx <= num + 11; colIdx++) {
    const startTimeCell = row.getCell(colIdx);
    const endTimeCell = row.getCell(colIdx + 1);
    colIdx++;

    const timeWindow: Models.TimeWindow = {
      startTime: convertDate(startTimeCell),
      endTime: convertDate(endTimeCell),
    };
    staffWorkTime.push(timeWindow);
  }
  return staffWorkTime;
}

//セルに非数値が入っている場合はエラー
const  parseCellToInt = (sheet: Excel.Worksheet, cell: Excel.Cell, errorMessage: string) =>{
  if (!isNaN(parseInt(cell.text))) {
    return parseInt(cell.text);
  } else if(cell.value === null) {
    return null;
  }else{
    throw new Error(errorMessage + '(' + sheet.name + 'シート)');
  }
}

