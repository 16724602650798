import * as Models from "../src/Models.tsx";
import * as Const from "../src/Const.tsx";
import {setKey,fromAddress} from "react-geocode";
import dayjs from 'dayjs';
import { detectAlert } from "./components/PlanTable.tsx";

export class CommonUtil {
  
  /**
   * 緯度経度から住所を更新(利用者ダイアログ)
   * @param lat 
   * @param lng 
   * @returns 
  */
  static updateAddress = (lat: number, lng: number): Promise<{ address: string, isAddressError: boolean, lat: number, lng: number }>  => {
    return new Promise((resolve, reject) => {
      if (isNaN(lat) && isNaN(lng)) {
        return;
      }
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat: lat, lng: lng } })
        .then((response) => {
          if (response.results[0]) {
            const address = response.results[0].formatted_address.replace(/^日本、〒\d+-\d+\s*/, '');;
            resolve({ address, isAddressError:false, lat, lng });
          } else {
            reject('住所が取得できません');
          }
        })
        .catch((e) => reject(e));
    });
  };

  /**
   * 住所から緯度経度を更新(利用者ダイアログ)
   * @param e 
   * @returns 
   */
  static updateLatLng = (e): Promise<{ address: string, isAddressError: boolean, lat: number, lng: number }>  => {
    return new Promise((resolve, reject) => {
      const address = e.target.value;
      setKey(Const.GOOGLE_MAPS_API_KEY_GEOCODING);
      fromAddress(address)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve({ address, isAddressError:false, lat, lng });
        })
        .catch((e) =>reject({ address,isAddressError:true}));
    });
  };

  /**
   * 時間の変更(スケジュールダイアログ)
   * @param newValue 
   * @param isStartTime 
   * @param commonData 
   * @param schedule 
   * @returns 
   */
  static changeScheduleTime = (newValue: dayjs.Dayjs, isStartTime: boolean, commonData: Models.CommonData, schedule: Models.Schedule) => {
    const time = newValue.toDate();
    const staffCode = schedule.staffCode;
    //既に予定が入っているか
    const existingVisitList = commonData.visitList.filter(s => s.day === schedule.day && s.staffCode === staffCode);
    const existingScheduleList = commonData.scheduleList.filter(s => s.id !== schedule.id && s.day === schedule.day && s.staffCode === staffCode);
    
    const allVisits = [...existingVisitList,...existingScheduleList]
    const newSchedule : Models.TimeWindow = {
      startTime: isStartTime ? time : schedule.startTime,
      endTime: isStartTime ? schedule.endTime : time
    };
  
    let startTime ;
    let endTime ;
    const isOverlapTime = CommonUtil.isOverlapTime(allVisits, newSchedule);

    if ((isStartTime && dayjs(schedule.endTime).isBefore(time)) ||
        (!isStartTime && dayjs(time).isBefore(schedule.startTime))) {
      // 開始時刻が終了時刻より後、または終了時刻が開始時刻より前の場合
      startTime = isStartTime ? Models.Time.createTime(time) : schedule.startTime;
      endTime = isStartTime ? schedule.endTime : Models.Time.createTime(time);
      
      return { isTimeError:true, startTime, endTime, isOverlapTime }
    } else {
      // 時間が正常な場合
      startTime =  Models.Time.createTime(newSchedule.startTime);
      endTime = Models.Time.createTime(newSchedule.endTime);

      return { isTimeError:false, startTime, endTime, isOverlapTime }
    }
  };

  /**
   * 時間の変更(利用者ダイアログ)
   * @param newValue 
   * @param index 
   * @param isStartTime 
   */
  static changeVisitTime = (newValue: dayjs.Dayjs, index: number, isStartTime: boolean ,customer) => {
    const time = newValue.toDate();
    const changeTime = [...customer.requireTime];
    if (isStartTime) {
      changeTime[index] = { startTime: Models.Time.createTime(time), endTime: customer.requireTime[index].endTime };
    } else {
      changeTime[index] = { startTime: customer.requireTime[index].startTime, endTime: Models.Time.createTime(time) };
    }
    return changeTime;
  }
  
  /**
   * 利用者情報ダイアログの「いつでも可」のボタンを押した時(利用者ダイアログ)
   * @param index 
   * @param customer 
   * @returns 
   */
  static anyTimeClick = (index: number , customer) => {
    const startTime = new Date(2024,1,1,0,0);
    const endTime = new Date(2024,1,1,23,50);
    const changeTime = [...customer.requireTime];
    changeTime[index] = { startTime: Models.Time.createTime(startTime), endTime: Models.Time.createTime(endTime) };
    return changeTime;
  };

  /**
   * 利用者情報ダイアログの「前後10分」のボタンを押した時(利用者ダイアログ)
   * @param index 
   * @param customer 
   * @returns 
   */
  static aroundTimeClick = (index : number , customer) => {
    const item = customer.requireTime[index];
    
    if (!item || item.startTime === null) {
        alert("希望開始時間を入力してください");
        return customer.requireTime;
    }
    
    const startTime = new Date(item.startTime);
    const beforeTime = new Date(startTime.setMinutes(startTime.getMinutes() - 10));
    const afterTime = new Date(startTime.setMinutes(startTime.getMinutes() + 20));
    
    const changeTime = [...customer.requireTime];
    changeTime[index] = { 
        startTime: Models.Time.createTime(beforeTime), 
        endTime: Models.Time.createTime(afterTime) 
    };
    
    return changeTime;
  };

  /**
   * 訪問頻度を増やした時(利用者ダイアログ)
   * @param commonData 
   * @param code 
   * @param visitPerWeek 
   */
  static getNewUnassignedVisits = (commonData : Models.CommonData , code : number , visitPerWeek : number) => {
    const unassinedList : Models.Visit[] = [];
    const initialUnassainedIds : number[] = commonData.visitList.filter( v => v.unassinedId != null).map( v => v.unassinedId);
    for (let i = 0; i < visitPerWeek; i++) {
      //割当不能IDリスト
      const unassignedIds = initialUnassainedIds.filter( id => id != null);
      const newUnassignedId  = CommonUtil.getMissingNumber(unassignedIds,1);//欠番になっている番号を割りあてる
      const unassigned: Models.Visit = {
         id: commonData.visitList.slice(-1)[0].id + 2 + i,
         customerCode: code,
         day: -1,
         staffCode: -1,
         arrivalTime: null,
         startTime: null,
         endTime: null,
         warning: [],
         alert: [],
         partnerVisitId: null,
         unassinedId: newUnassignedId, 
         isPrimaryStaff: false,
         isGeneralNursing: false,
         isTimeHighlighted: false,
       };
       initialUnassainedIds.push(newUnassignedId)
       unassinedList.push(unassigned)
     };
     return unassinedList;
  };

  /**
   * 右クリックの座標を計算して、追加する利用者、スケジュールの曜日・スタッフ・時刻を取得
   * @returns 
   */
  static calculateClickData = (commonData : Models.CommonData , contextMenu: Models.ContextMenuPosition) => {
    
    const rowHeight = Const.LAYOUT.TIMELINE_CELL_HEIGHT; //セル1マス分の高さ
    const colWidth = Const.LAYOUT.TIMELINE_CELL_WIDTH;   //セル1マス分の幅
    const dayBorder = 1; //１日の区切り
    const dayWidth = colWidth * commonData.staffList.length + dayBorder; //1日の幅
    //曜日
    const day = (Math.floor(contextMenu.mouseX/ dayWidth));  
    //スタッフ
    const xInDay = contextMenu.mouseX % dayWidth;
    const staffCode = xInDay < dayWidth - dayBorder ?  Math.floor(xInDay / colWidth) : commonData.staffList.length  - 1; 
    //訪問時間
    const row = Math.floor((contextMenu.mouseY - Const.DRAGGABLE_VISIT_LAYER_TOP)/ rowHeight);
    const startHour = Const.TIMELINE_START_HOUR; //タイムライン開始時刻(例 8時)
    const startTimeH = Math.floor(row / 4) + startHour;
    const startTimeM = (row % 4) * 15;
    const startTime = new Models.Time(('0' + startTimeH).slice(-2) + ":" + ('0' + startTimeM).slice(-2));
    const endTime = new Models.Time(startTime.toString());
    endTime.setMinutes(endTime.getMinutes() + 35); // 35分を追加

    return { day,staffCode,startTime,endTime }
  }; 

  /**
   * 追加するスケジュールがほかの予定と被っていないか
   * @param daySchedule 
   * @param newSchedule 
   * @returns 
   */
  static isOverlapTime = (dayScheduleList, newSchedule: Models.TimeWindow) => {
    const newStartTime = newSchedule.startTime;
    const newEndTime = newSchedule.endTime;

    for (let i = 0; i < dayScheduleList.length; i++) {
        const existingStartTime = dayScheduleList[i].startTime;
        const existingEndTime = dayScheduleList[i].endTime;

        if (newStartTime < existingEndTime && newEndTime > existingStartTime) {
            return true; 
        }
    }
    return false; 
  }

  /**
   * RBG配列を返す
   * @param argb 
   * @returns 
   */
  static argbToRgb = (argb: string): number[] =>{
    const bigint = parseInt(argb, 16);
    const red = (bigint >> 16) & 255;
    const green  = (bigint >> 8) & 255;
    const blue = bigint & 255;
    return [red, green, blue];
  }

  /**
   * HEXカラーコードを返す
   * @param rgb
   * @returns
   */
  static rgbToString = (rgb: number[]): string | null => {
    if (!rgb) {
      return null;
    }
    const red = rgb[0];
    const green = rgb[1];
    const blue = rgb[2];
    const bigint = (red << 16) | (green << 8) | blue;
    const argbString = bigint.toString(16).padStart(6, "0");
    return argbString;
  }

  /**
   * 配列の数の中で、startNumber以上かつ欠番になっている数のうち最小のものを求める
   * @param numArray 
   * @param startNumber 
   */
  static getMissingNumber(numArray : number[], startNumber : number){
    //配列を昇順に並び替える
    const sortedArray = numArray.concat();
    sortedArray.sort((x,y) => (x - y));

    //startNumberが最小欠番のとき
    if(sortedArray.length == 0 || startNumber < sortedArray[0]){
      return startNumber;
    }

    //1つずつ欠番がないかチェックしていく
    let currentNumber = sortedArray[0];
    for(let i = 1; i<sortedArray.length; i++){
      if(sortedArray[i] - currentNumber <= 1){
        //欠番ではない
        currentNumber = sortedArray[i];
      }else{
        //欠番発見
        return currentNumber + 1;
      }
    }

    //欠番がない場合、配列最後の数値+1
    return currentNumber + 1;
  }

  /**
   * 1つ上から当訪問先までの移動時間、および当訪問先から1つ下までの移動時間を取得する(非同期)
   * @param previousVisit 
   * @param currentVisit
   * @param nextVisit 
   * @param commonData
   * @param cacheData
   * @returns 
   */
  static fetchTravelMinutes(previousVisit, currentVisit, nextVisit, commonData, cacheData): Promise<{travelMinutesAbove: number, travelMinutesBelow: number}>{
    //GoogleMapsDirectionのAPI
    const directionsService = new google.maps.DirectionsService();

    //移動時間
    let minAbove = 0;
    let minBelow = 0;

    //当訪問先の利用者情報取得(緯度経度を知るため)
    const currentCustomer = commonData.customerList.find(c => c.code == currentVisit.customerCode);

    return new Promise((resolve : (value: {travelMinutesAbove: number, travelMinutesBelow: number}) => void )=> {
        if(previousVisit == null && nextVisit == null){
          //上にも下にも訪問先がいない場合
          resolve({travelMinutesAbove:0, travelMinutesBelow:0});
        }else{
          //Cacheから移動時間を取ってこれたかどうか
          let isPrevOK = true;
          let isNextOK = true;

          //Cacheに保存されている全区間(leg)を配列にして取得する
          const legs : google.maps.DirectionsLeg[] = [];
          cacheData.googlemapsDirections.forEach( g =>{
            if(g.result != undefined && g.result.routes[0] != undefined){
              legs.push(...g.result.routes[0].legs)
            }
          }) 

          //緯度経度のズレの許容範囲
          const tolerance = 0.001; 

          //1つ上の訪問先→この訪問先
          if(previousVisit != null){
            if(previousVisit.customerCode != null){
              //訪問先ブロックの場合
              const previousCustomer = commonData.customerList.find(c => c.code == previousVisit.customerCode);
              const matchLegA = legs.find( l =>
                //緯度経度の誤差がtolerance以下のものをとってくる
                // @ts-expect-error lat is not function
                Math.max(Math.abs(l.start_location.lat - previousCustomer.lat),Math.abs(l.start_location.lng - previousCustomer.lng),Math.abs(l.end_location.lat - currentCustomer.lat),Math.abs(l.end_location.lng - currentCustomer.lng)) <= tolerance 
              );
              if(matchLegA != undefined){
                minAbove = matchLegA.duration.value / 60;
                //5分単位に丸める(切り上げ)
                minAbove = Math.floor( minAbove / 5) * 5 + 5;
              }else{
                isPrevOK = false;
              }
            }else{
              //スケジュールブロックの場合
              minAbove = 0;
            }
            
          }
          
          //この訪問先→1つ下の訪問先
          if(nextVisit != null){
            if(nextVisit.customerCode != null){
              //訪問先ブロックの場合
              const nextCustomer = commonData.customerList.find(c => c.code == nextVisit.customerCode);
              const matchLegB = legs.find( l =>
                //緯度経度の誤差がtolerance以下のものをとってくる
                // @ts-expect-error lat is not function
                Math.max(Math.abs(l.start_location.lat - currentCustomer.lat),Math.abs(l.start_location.lng - currentCustomer.lng),Math.abs(l.end_location.lat - nextCustomer.lat),Math.abs(l.end_location.lng - nextCustomer.lng)) <= tolerance 
              );
              if(matchLegB != undefined){
                minBelow = matchLegB.duration.value / 60;
                //5分単位に丸める(切り上げ)
                minBelow = Math.floor( minBelow / 5) * 5 + 5;
              }else{
                isNextOK = false;
              }
            }else{
              //スケジュールブロックの場合
              minBelow = 0;
            }
          }
          
          // Cacheから取得できた場合はこれで終了
          if (isPrevOK && isNextOK) {
            resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
          } else {
            //API呼び出し用のリクエストを作成する
            const createRequest = (origin, destination, waypoints = []) => ({
              origin: { lat: origin.lat, lng: origin.lng },
              destination: { lat: destination.lat, lng: destination.lng },
              waypoints: waypoints.length ? [{ location: { lat: waypoints[0].lat, lng: waypoints[0].lng } }] : [],
              travelMode: google.maps.TravelMode.DRIVING,
            });

            //APIから取得したデータを処理してキャッシュに保存
            const processAndCacheApiData = (result, above: boolean, below: boolean) => {
              if (result.routes.length) {
                if (above) {
                  minAbove = Math.floor(result.routes[0].legs[0].duration.value / 60 / 5) * 5 + 5;
                }
                if (below) {
                  minBelow = Math.floor(result.routes[0].legs[result.routes[0].legs.length - 1].duration.value / 60 / 5) * 5 + 5;
                }
                const newCache = {
                  result: result,
                  savedDate: new Date(),
                };
                cacheData.googlemapsDirections.push(newCache);
              } else {
                console.error("No routes found");
              }
            };

            // 上下にセルが存在する場合
            if (!isPrevOK && !isNextOK) {
              const previousCustomer = commonData.customerList.find(c => c.code == previousVisit.customerCode);
              const nextCustomer = commonData.customerList.find(c => c.code == nextVisit.customerCode);

              const request = createRequest(previousCustomer, nextCustomer, [currentCustomer]);

              directionsService.route(request, (result, status) => {
                if (status == "OK") {
                  processAndCacheApiData(result, true, true);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                } else {
                  console.error(status);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                }
              });
            } 
            // 上にセルが存在する場合
            else if (!isPrevOK) {
              const previousCustomer = commonData.customerList.find(c => c.code == previousVisit.customerCode);

              const request = createRequest(previousCustomer, currentCustomer);

              directionsService.route(request, (result, status) => {
                if (status == "OK") {
                  processAndCacheApiData(result, true, false);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                } else {
                  console.error(status);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                }
              });
            } 
            // 下にセルが存在する場合
            else if (!isNextOK) {
              const nextCustomer = commonData.customerList.find(c => c.code == nextVisit.customerCode);

              const request = createRequest(currentCustomer, nextCustomer);

              directionsService.route(request, (result, status) => {
                if (status == "OK") {
                  processAndCacheApiData(result, false, true);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                } else {
                  console.error(status);
                  resolve({travelMinutesAbove:minAbove, travelMinutesBelow:minBelow});
                }
              });
            } else {
              // どちらにもセルが存在しない場合
              console.error("No visits found");
              resolve({travelMinutesAbove:5, travelMinutesBelow: 5});
            }
          }

                    
        }
      })
  }

  /**
   * 警告を更新(すべての訪問先の警告を更新)
   * @param commonData 
   * @returns 
   */
  static updateAlert = (commonData: Models.CommonData) => {
    for(let idx = 0; idx < commonData.visitList.length; idx++){
      const visit = commonData.visitList[idx];
      const customer = commonData.customerList.find( c => c.code === visit.customerCode);
      if(customer != null){
        const alertSet: Models.AlertSet = detectAlert({visit: visit, customer: customer}, commonData);
        //警告をセット
        visit.warning = alertSet.warningList;
        visit.alert = alertSet.alertList;
      }
    }
    return commonData.visitList;
  }

  /**
   * リストから同じ id と partnerVisitId を持つ要素のペア数を数える関数
   * @param visitList 
   * @returns 
   */
  static countDuplicatePairs = (visitList: { id: number, partnerVisitId: number }[]): number => {
    let count = 0;
    const visitedPairs = new Set<string>(); // すでに数えたペアを管理する

    visitList.forEach((visit) => {
      const { id, partnerVisitId } = visit;

      // すでにカウントしたペアでなく、反対向きのペアもあるか確認する
      const firstPair = `${id}-${partnerVisitId}`;
      const secondPair = `${partnerVisitId}-${id}`;

      if (!visitedPairs.has(firstPair) && !visitedPairs.has(secondPair)) {
        // 新しいペアなら、カウントアップして追加する
        count++;
        visitedPairs.add(firstPair);
      }
    });
    return count;
  }
}


