import * as React from "react";
import {PublicParams, PublicCaches} from '../forms/MainForm.tsx';
import * as Const from '../Const.tsx';
import * as Models from '../Models.tsx';
import { openDatabase, upsertItem, getItem } from '../indexedDBUtils.tsx';

/**
 * Directionコンポーネント
 * GoogleMapsAPIと通信して経路情報を取得するためのコンポーネント
 * 取得した経路情報はcommonData.directionsListにセットされる
 */
export function Direction(props:{callback: (dlist:Models.DirectionsList[]) => void}) {
      
      //共通データ
      const commonData = React.useContext(PublicParams);

      //キャッシュデータ
      const cacheData = React.useContext(PublicCaches);

      //DirectionsService
      const directionsService = new google.maps.DirectionsService();

      //処理中のコース
      const processingCourseList : ProcessingCourse[] = [];

      //共通データにセットするdirectionList(データをここに貯めて、最後にまとめてセットする)
      const directionsListTmp = commonData.directionsList;

      React.useEffect(()=>{   
        //各曜日・各スタッフ毎にループ
        for(let day = 0; day < Const.WORK_DAY_NUM ; day ++){
          for(let s = 0; s < commonData.staffList.length; s++){
            const staff = commonData.staffList[s];

            //曜日・スタッフに対応するデータを抽出
            const directions = commonData.directionsList?.filter( x => x.day == day && x.staffCode == staff.code);
            const visits = commonData.visitList?.filter( x => x.day == day && x.staffCode == staff.code).sort((a, b) => a.startTime > b.startTime ? 1 : -1);
            
            //経路が検索済みかどうか
            const isNoDirections = (typeof directions === 'undefined' || directions.length == 0);            

            //利用者はいるが、経路が未検索のコースについて経路検索を行う
            if(isNoDirections && visits != null && visits.length > 0){
              
              //1人しかいないコースはnull directionを返す
              if(visits.length == 1){
                const emptyDirections = {
                  day: day,
                  staffCode : staff.code,
                  directions : null,
                };
                directionsListTmp.push(emptyDirections);
                continue;
              }

              //訪問データに対応する利用者データを取得
              const customerToVisit = visits.map( v => commonData.customerList.find( c => c.code == v.customerCode));

              //始点・経由地・終点
              const origin = { lat: customerToVisit[0].lat, lng: customerToVisit[0].lng };
              const waypoints = [];
              for(let i = 1; i < customerToVisit.length -1; i++){
                waypoints.push({location:{lat: customerToVisit[i].lat, lng: customerToVisit[i].lng }})
              }
              const destination = { lat: customerToVisit[customerToVisit.length -1].lat, lng: customerToVisit[customerToVisit.length -1].lng };

              //GoogleMapsAPIにリクエストする内容
              const request = {
                origin: origin,
                waypoints:waypoints,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING
              };

              //コールバック関数
              const callback = function(result:google.maps.DirectionsResult, status:google.maps.DirectionsStatus | string){
                console.log(`GoogleMapsDirectionService ${status}`); 
                //経路検索中リストから除外
                const idx = processingCourseList.findIndex( p => p.day == day && p.staffCode == staff.code);
                processingCourseList.splice(idx, 1);

                //directionsListに登録
                if (status == 'OK' || status == 'Cache') {
                  const newDirections = {
                    day: day,
                    staffCode : staff.code,
                    directions : result,
                  };
                  if(commonData.directionsList != null){
                    directionsListTmp.push(newDirections);
                  }
                }

                //キャッシュに登録
                if(status == 'OK'){
                  const newCache : Models.GooglemapsDirectionsCache ={
                    result : result,
                    savedDate : new Date(),
                  }
                  cacheData.googlemapsDirections.push(newCache);

                  // データベースを開く処理
                  openDatabase()
                  .then((database) => {
                    upsertItem(database, 'cacheData', JSON.stringify(cacheData));
                  })
                  .catch(() =>{
                    console.log('Database connection failed in Direction.tsx');
                  });                 
                }

                //処理中のコースがない場合は終了
                if(processingCourseList.length == 0){
                  //リクエスト結果を共通データにセット
                  commonData.setDirectionsList(directionsListTmp);
                  //Directionコンポーネントのコールバック関数呼び出し
                  props.callback(directionsListTmp);
                }
              }

              //同じコースが経路検索中になっている場合はskip
              if(processingCourseList.some( p => p.day == day && p.staffCode == staff.code)) continue;

              //経路検索中リストに入れる
              processingCourseList.push({
                day: day,
                staffCode : staff.code,
              });

              //補助関数(2つのpointが同じ位置かどうか調べる)
              const isSameLatLng = function(pointA, pointB : {lat:number, lng:number}) : pointA is google.maps.Place {
                if(pointA.location === undefined || pointA.location === null) return false;
                if(typeof pointA.location.lat === "function"){
                  //pointA.locationがgoogle.maps.LatLngのとき
                  if(pointA.location.lat() == pointB.lat && pointA.location.lng() == pointB.lng) return true;
                }else{
                  //pointA.locationがgoogle.maps.LatLngLiteralのとき
                  if(pointA.location.lat == pointB.lat && pointA.location.lng == pointB.lng) return true;
                }
                return false;
              }

              //キャッシュにいるかどうか調べる
              const caches = cacheData.googlemapsDirections.filter( (g) => {
                if(!isSameLatLng(g.result.request.origin, request.origin)) return false;
                if(!isSameLatLng(g.result.request.destination, request.destination)) return false;
                if(g.result.request.travelMode != request.travelMode) return false;
                if(g.result.request.waypoints.length != request.waypoints.length) return false;
                for(let i=0; i<g.result.request.waypoints.length; i++){
                  if(!isSameLatLng(g.result.request.waypoints[i], request.waypoints[i])){
                    return false;
                  }
                }
                return true;
              })
              
              if(caches != null && caches.length > 0){
                //キャッシュに保存された結果を使う
                caches.sort((a,b) => b.savedDate.getTime() - a.savedDate.getTime());
                callback(caches[0].result, 'Cache');
              }else{
                //GoogleMapsAPIに送信
                directionsService.route(request, callback);
              }           
            }
          }
        }

        //処理中のコースがない場合は終了
        if(processingCourseList.length == 0){
          //リクエスト結果を共通データにセット
          commonData.setDirectionsList(directionsListTmp);
          //コールバック関数を実行
          props.callback(directionsListTmp);
        }

      },[commonData.visitList])

      return(
        <></>
      );
}

class ProcessingCourse{
  day: number;
  staffCode : number;
}
