// TypeScriptファイル内でexportを利用していない場合は、export{};を定義
export {};

// 拡張メソッドの定義
declare global {
    interface Date {
        /** 分を加算する */
        addMinutes(minutes: number): void;
    }
}

/**
 * 分を加算する
 */
Date.prototype.addMinutes = function (minutes: number) {
    this.setMinutes(this.getMinutes() + minutes);
    return;
};