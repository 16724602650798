import './MainForm.css';
import * as Models from "../Models.tsx";
import {Grid} from '@mui/material';
import * as React from 'react'; 
import {useState ,createContext} from 'react';
import Split from "react-split";
import {PlanTable} from '../components/PlanTable.tsx';
import {Map} from '../components/Map.tsx';
import {InfomationWindow} from '../components/InfomationWindow.tsx';
import * as Const from '../Const.tsx';
import { openDatabase, upsertItem, getItem } from '../indexedDBUtils.tsx';
import { Wrapper } from '@googlemaps/react-wrapper';


/**
 * 共通パラメータの初期値(定義しないとエラーになる)
 */
const initialPublicParams : Models.CommonData ={
  //選択中のブロック
  activeVisitIds : [] as Models.ActiveVisitIdentity[], //アクティブブロックID,
  setActiveVisitIds : () => {},
  //ダイアログが開かれているかどうか
  dialogName : null,
  setDialogName : () =>{},
  //訪問リスト
  visitList : [],
  setVisitList :  () => {},
  //利用者リスト
  customerList : [],
  setCustomerList : () => {},
  //職員リスト
  staffList : [],
  setStaffList : () => {},
  //経路リスト
  directionsList:[],
  setDirectionsList: () => {},
  //職員スケジュールリスト
  scheduleList : [],
  setScheduleList :() => {}
}

/**
 * キャッシュデータの初期値(定義しないとエラーになる)
 */
const initialPublicCaches : Models.CacheData ={
  googlemapsDirections : [],
}

//変数の宣言
export const PublicParams = createContext<Models.CommonData>(initialPublicParams);
export const PublicCaches = createContext<Models.CacheData>(initialPublicCaches);

/////////////
//メイン画面
/////////////

/**
 * メイン画面(タイムラインや地図が表示されている画面)
 * @returns 
 */
export function MainForm(props : {commonData : Models.CommonData, cacheData: Models.CacheData}){

  //commonDataの中身を変更できるようにuseStateを噛ます
  [props.commonData.visitList, props.commonData.setVisitList] = useState(props.commonData.visitList); 
  [props.commonData.customerList, props.commonData.setCustomerList] = useState(props.commonData.customerList); 
  [props.commonData.staffList, props.commonData.setStaffList] = useState(props.commonData.staffList); 
  [props.commonData.activeVisitIds, props.commonData.setActiveVisitIds] = useState(props.commonData.activeVisitIds); 
  [props.commonData.directionsList, props.commonData.setDirectionsList] = useState(props.commonData.directionsList);
  [props.commonData.scheduleList, props.commonData.setScheduleList] = useState(props.commonData.scheduleList);

  //ダイアログの表示状態制御用
  [props.commonData.dialogName, props.commonData.setDialogName] = React.useState(null);
  let dialog:React.JSX.Element;
  if(!props.commonData.dialogName){
    dialog=<></>
  }

  //commonDataに変更があるたびにindexedDBに保存
  React.useEffect(()=>{
    // データベースを開く処理
    openDatabase()
      .then((database) => {
        //commonDataに保存
        upsertItem(database, 'commonData', JSON.stringify(props.commonData));
        database.close();
      })
      .catch(() => {
        console.log("Database connection failed in Direction.tsx");
      });
  },[props.commonData.visitList,props.commonData.customerList,props.commonData.scheduleList]);

  return (
    <div id="body-wrapper">
      <PublicCaches.Provider value={props.cacheData}>
        <PublicParams.Provider value={props.commonData}>
          <Split 
            className="split"
            sizes={[Const.LAYOUT.SPLIT_LEFT_PERCENTAGE,100 - Const.LAYOUT.SPLIT_LEFT_PERCENTAGE]}
            gutterSize={4}
            style={{height : '100%'}}>
              <Grid id='plan-panel'>
                <Wrapper apiKey={Const.GOOGLE_MAPS_API_KEY}>
                  <PlanTable></PlanTable> {/* スケジュール表(画面左側) */}
                </Wrapper>
              </Grid>
              <Grid container direction="column">
                  <Grid item id='map-panel'>
                    <Map></Map> {/* 地図(画面右上) */}
                  </Grid>
                  <Grid item id='info-panel'>
                    <InfomationWindow></InfomationWindow> {/* 情報窓(画面右下) */}
                  </Grid>
                </Grid>
          </Split>
          {dialog}
        </PublicParams.Provider>
      </PublicCaches.Provider>
    </div>
  )
  }