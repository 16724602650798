import * as React from 'react';
import './InfomationWindow.css';
import {PublicParams} from '../forms/MainForm.tsx';
import * as Const from '../Const.tsx';

/**
 * InfomationWindowコンポーネント
 * @returns 
 */
export function InfomationWindow(){
    //const TWO_STAFF_MESSAGE = ['どちらでもよい','できれば2人','2人'];
    const TWO_STAFF_MESSAGE = {
        0 : 'どちらでもよい',
        50 : 'できれば2人',
        100 : '2人',
    };
    const REQUIRE_SEX_MESSAGE = ['どちらでもよい','女性希望','男性希望'];
    const STAFF_SEX_NAME = ['その他','女','男'];
    const STAFF_LICENSE_NAME = ['その他','看護師','准看護師','理学療法士'];

    //共通データ
    const commonData = React.useContext(PublicParams);
    
    if(commonData.activeVisitIds.length > 0){
        switch (commonData.activeVisitIds[0].type){
            case Const.ActiveBlockTypes.VISIT:{
                //訪問先ブロックが選択されているとき
                const visit = commonData.visitList.find( p => p.id === commonData.activeVisitIds[0].id);
                if (!visit) return;
                const customer = commonData.customerList.find( c => c.code === visit.customerCode);
    
                //希望時刻
                const isResttictedMon = (customer.requireTime[0].startTime != null || customer.requireTime[0].endTime != null );
                const isResttictedTue = (customer.requireTime[1].startTime != null || customer.requireTime[1].endTime != null );
                const isResttictedWed = (customer.requireTime[2].startTime != null || customer.requireTime[2].endTime != null );
                const isResttictedThu = (customer.requireTime[3].startTime != null || customer.requireTime[3].endTime != null );
                const isResttictedFri = (customer.requireTime[4].startTime != null || customer.requireTime[4].endTime != null );
                const isResttictedSat = (customer.requireTime[5].startTime != null || customer.requireTime[5].endTime != null );
            
                //終了時刻
                let eTime : string = "";
                let eTime_temp : Date;
                if(visit.startTime != null){
                    eTime_temp = new Date('2023/3/10 ' + visit.startTime.toString()); //日付はダミー(入れないとDateに変換できない)
                    eTime_temp.setMinutes(eTime_temp.getMinutes() + 35);
                    eTime = `${eTime_temp.getHours()}:${eTime_temp.getMinutes()}`;
                }

                //待ち時間
                let wTime = 0;
                if(visit.startTime != null && visit.arrivalTime != null){
                  const diffTime = visit.startTime.getTime() - visit.arrivalTime.getTime();
                  wTime = diffTime / (1000 * 60); // ミリ秒を分に変換
                }
                
                //文字列化
                const aTime = visit.arrivalTime ? visit.arrivalTime.toString() : '';
                const sTime = visit.startTime ? visit.startTime.toString() : '';
                eTime = visit.endTime ? visit.endTime.toString() : '';

                let isSpecifiedTimes;
                if(customer.specifiedTimes === 0){
                    //曜日希望時刻が指定されていない場合はグレーの文字で表示する。
                    isSpecifiedTimes =  true;
                }
    
                return(
                    <div id="infomation-window">
                        <h2>訪問先情報</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{borderBottom:'1px solid #888'}}>
                                    {aTime} 到着 → {'(' + wTime + '分待ち)'} → {sTime} 開始 → {eTime} 終了
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-name">ID</td>
                                    <td>{customer.code}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">名前</td>
                                    <td>{customer.name}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">住所</td>
                                    <td>{customer.address}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">訪問頻度</td>
                                    <td>{customer.visitPerWeek} 回 / 週</td>
                                </tr>
                                <tr>
                                    <td className="item-name">希望人数</td>
                                    <td>{TWO_STAFF_MESSAGE[customer.requireTwoStaffs]/* TWO_STAFF_MESSAGE[Math.floor(customer.requireTwoStaffs * (TWO_STAFF_MESSAGE.length - 1))] */}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">希望時刻</td>
                                    <td>
                                        <ul className={"non-style" + (isSpecifiedTimes ? ' specifiedTimes-customer' : '')} >
                                            <li>月 {(isResttictedMon ? `${customer.requireTime[0].startTime || ""}～${customer.requireTime[0].endTime || ""}` : "")}</li>
                                            <li>火 {(isResttictedTue ? `${customer.requireTime[1].startTime || ""}～${customer.requireTime[1].endTime || ""}` : "")}</li>
                                            <li>水 {(isResttictedWed ? `${customer.requireTime[2].startTime || ""}～${customer.requireTime[2].endTime || ""}` : "")}</li>
                                            <li>木 {(isResttictedThu ? `${customer.requireTime[3].startTime || ""}～${customer.requireTime[3].endTime || ""}` : "")}</li>
                                            <li>金 {(isResttictedFri ? `${customer.requireTime[4].startTime || ""}～${customer.requireTime[4].endTime || ""}` : "")}</li>
                                            <li>土 {(isResttictedSat ? `${customer.requireTime[5].startTime || ""}～${customer.requireTime[5].endTime || ""}` : "")}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-name">性別希望</td>
                                    <td>{REQUIRE_SEX_MESSAGE[customer.requireSex]}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">その他希望</td>
                                    <td>{customer.others}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            case Const.ActiveBlockTypes.STAFF: {
                const staff = commonData.staffList.find( s => s.code === commonData.activeVisitIds[0].id);
                //スタッフ名のエリアを選択したとき
                return(
                    <div id="infomation-window">
                        <h2>スタッフ情報</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="item-name">社員コード</td>
                                    <td>{staff.code}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">名前</td>
                                    <td>{staff.name}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">性別</td>
                                    <td>{STAFF_SEX_NAME[staff.sex]}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">資格</td>
                                    <td>{STAFF_LICENSE_NAME[staff.license]}</td>
                                </tr>
                                <tr>
                                    <td className="item-name">勤務時刻</td>
                                    <td>
                                        <ul className="non-style">
                                            <li>月 {staff.workTime[0].startTime || staff.workTime[0].endTime ? `${staff.workTime[0].startTime || ""}～${staff.workTime[0].endTime || ""}` : ''}</li>
                                            <li>火 {staff.workTime[1].startTime || staff.workTime[1].endTime ? `${staff.workTime[1].startTime || ""}～${staff.workTime[1].endTime || ""}` : ''}</li>
                                            <li>水 {staff.workTime[2].startTime || staff.workTime[2].endTime ? `${staff.workTime[2].startTime || ""}～${staff.workTime[2].endTime || ""}` : ''}</li>
                                            <li>木 {staff.workTime[3].startTime || staff.workTime[3].endTime ? `${staff.workTime[3].startTime || ""}～${staff.workTime[3].endTime || ""}` : ''}</li>
                                            <li>金 {staff.workTime[4].startTime || staff.workTime[4].endTime ? `${staff.workTime[4].startTime || ""}～${staff.workTime[4].endTime || ""}` : ''}</li>
                                            <li>土 {staff.workTime[5].startTime || staff.workTime[5].endTime ? `${staff.workTime[5].startTime || ""}～${staff.workTime[5].endTime || ""}` : ''}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
             case Const.ActiveBlockTypes.SCHEDULE: {
                const schedule = commonData.scheduleList.find( s => s.id === commonData.activeVisitIds[0].id);
                if (!schedule) return;
                //スケジュールのエリアを選択したとき
                return(
                    <div id="infomation-window">
                        <h2>スケジュール情報</h2>
                        {schedule.startTime.toString()} 開始 → {schedule.endTime.toString()} 終了
                        <table>
                            <tbody>
                                <tr>
                                    <td>内容</td>
                                    <td style={{paddingLeft :'20px'}}>{schedule.content}</td>
                                </tr>
                                <tr>
                                    <td>備考</td>
                                    <td style={{paddingLeft :'20px'}}>{schedule.other}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
             default:
                //念のため
                return(
                    <div id="infomation-window">
                    </div>
                )
        }
    }else{
        //何も選択されていない時
        return(
            <div id="infomation-window">
                <h2>ここに詳細情報が表示されます</h2>
            </div>
        )
    }
    
    
}