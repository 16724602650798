import * as React from 'react';
import * as Const from './Const.tsx';

/////////////////////////
//interface(型)を定義
/////////////////////////

/**
 * コンポーネント間で共有したいデータ
 */
export interface CommonData {
  //選択中のブロック
  activeVisitIds : ActiveVisitIdentity[],
  setActiveVisitIds : React.Dispatch<React.SetStateAction<ActiveVisitIdentity[]>>,
  //ダイアログが開かれているかどうか
 /*  isDialogOpen : boolean,
  setIsDialogOpen : React.Dispatch<React.SetStateAction<boolean>>, */
  dialogName : Const.DialogNameType,
  setDialogName : React.Dispatch<React.SetStateAction<Const.DialogNameType>>,
  //訪問リスト
  visitList : Visit[],
  setVisitList : React.Dispatch<React.SetStateAction<Visit[]>>;
  //利用者リスト
  customerList : Customer[];
  setCustomerList : React.Dispatch<React.SetStateAction<Customer[]>>;
  //職員リスト
  staffList : Staff[];
  setStaffList : React.Dispatch<React.SetStateAction<Staff[]>>;
  //経路リスト
  directionsList: DirectionsList[];
  setDirectionsList : React.Dispatch<React.SetStateAction<DirectionsList[]>>;
  //職員スケジュールリスト
  scheduleList : Schedule[];
  setScheduleList : React.Dispatch<React.SetStateAction<Schedule[]>>;
} 

/**
 * アクティブブロックを一意に判別する情報
 */
export interface ActiveVisitIdentity{
  type : Const.ActiveBlockTypeType, //1:訪問先 2:スタッフ -1:何も選択されていない
  id : number   //ID 訪問先ならVisitDataのID,スタッフならStaffDataのID
}

/**
 * 訪問データ
 * ★マークはExcel取り込み時にセットされる
 */
export interface Visit extends VisitBase{
  id : number;   //訪問データごとに一意に割りあてられる番号 ★
  customerCode : number;  //利用者コード ★
  warning : Const.AlertMessageTypes[]; //ワーニング
  alert : Const.WarningMessageTypes[];   //アラート
  partnerVisitId? : number;  //2人訪問のとき、相方のVisitのid ★
  unassinedId? : number;     //割当不能のとき、何番目の割当不能か ★
  isPrimaryStaff : boolean;  //2人訪問のメイン担当かどうか ★
  isGeneralNursing : boolean; //一般訪問看護かどうか ★
  isTimeHighlighted : boolean; //時刻を強調するかどうか ★
}

/**
 * 訪問データBase
 * (StaffScheduleと共通の属性)
 * ★マークはExcel取り込み時にセットされる
 */
export interface VisitBase{
  day : number;           //曜日 (0:月 1:火 2:水 …) ★
  staffCode : number;     //職員コード ★
  arrivalTime : Time; //到着時刻
  startTime : Time;   //訪問開始時刻 ★
  endTime : Time;     //訪問終了時刻 ★
}

/**
 * 利用者データ
 * ★マークはExcel取り込み時にセットされる
 */
export interface Customer{
  code : number;  //利用者コード ★
  name : string;  //利用者名 ★
  address : string; //住所 ★
  matchLevel : number; //住所精度 ★
  matchLevelStr : string; //住所精度(string) ★
  lat : number;   //緯度 ★
  lng : number;   //経度 ★
  color : number[];   //背景色RGB配列 ★
  visitPerWeek : number;     //訪問頻度 ★
  requireTwoStaffs : number; //2人訪問の希望度合い ★
  requireSex : number;       //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
  specifiedTimes : number;  //曜日希望時刻が指定されているか(0:指定なし 1:指定あり) ★
  requireTime : TimeWindow[];//希望時刻(月～土) ★
  others : string;           //その他希望 ★
}

/**
 * スタッフデータ
 * ★マークはExcel取り込み時にセットされる
 */
export interface Staff{
  code : number;    //社員コード ★
  name : string;    //社員名 ★
  sex : number;     //性別(1:女性 2:男性 0:その他) ★
  license : number; //資格(1:正看護師 2:准看護師 0:無資格/その他) ★
  workTime : TimeWindow[];  //勤務時刻(月～土) ★

}

/**
 * 経路データ
 * (GoogleMapsDirectionServiceに問い合わせた経路を保持しておく)
 */
export interface DirectionsList{
  day : number;           //曜日 (0:月 1:火 2:水 …) 
  staffCode : number;     //職員コード
  directions : google.maps.DirectionsResult; //GoogleMapsAPIに経路を問い合わせた結果
}

/**
 * スタッフスケジュールデータ
 */
export interface Schedule extends VisitBase{
  id : number;
  content : string; //内容
  other : string;   //備考
}

/**
 * 時刻枠
 */
export interface TimeWindow{
  startTime: Time; //時刻開始(指定がない場合はnull)
  endTime: Time;   //時刻終了(指定がない場合はnull)
}

/**
 * 時刻クラス
 * [使用例] startTime = new Time("12:30");
 */
export class Time extends Date{
  constructor(timeString : string){
    const fullDateString = `2000-01-01T${timeString}:00`;
    super(fullDateString);
  }

  //Date型を引数にして時刻クラスのインスタンスを作る
  static createTime(date: Date) { 
    const hours = ('00' + date.getHours()).slice(-2);
    const minutes = ('00' + date.getMinutes()).slice(-2);
    return new Time(`${hours}:${minutes}`);
  }

  //Date.toString()をオーバーライド
  toString(){
    const hours = ('00' + this.getHours()).slice(-2);
    const minutes = ('00' + this.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  }
}

/**
 * JSON.parse()で文字列をオブジェクトに変換する際、型を適切なものに変換する
 * @param key 
 * @param value 
 */
export function reverser(key, value){
  if(key == 'arrivalTime' || key =='startTime' || key =='endTime'){
    if(value != null){
      value = Time.createTime(new Date(value));
    }
  }else if(key == 'savedDate'){
    if(value != null){
      value = new Date(value);
    }
  }
  return value
}

/**
 * キャッシュデータ
 */
export interface CacheData {
  googlemapsDirections: GooglemapsDirectionsCache[]
}

/**
 * Googlemaps Directions APIのリクエスト結果をキャッシュ保存するためのクラス
 */
export interface GooglemapsDirectionsCache{
  result:google.maps.DirectionsResult,
  savedDate:Date,
}

/**
 * 右クリックメニューの位置
 */
export interface ContextMenuPosition{
  mouseX:number,
  mouseY:number
}

/**
 * アラートリスト
 */
export type AlertSet = {
  alertList: Const.AlertMessageTypes[];
  warningList: Const.WarningMessageTypes[];
  dependencyVisitList: Visit[]; //依存する訪問先
}