import * as Models from "../Models.tsx";
import Excel from "exceljs";
import * as ExcelConst from '../ExcelConst.tsx';
import axios from 'axios'
import * as Const from '../Const.tsx';
import { CommonUtil } from '../CommonUtil.tsx';

/**
 * Excelファイルダウンロード
 * @param commonData 
 */
export async function writeExcelFile(commonData: Models.CommonData): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios.get("/document/訪問計画.xlsx", { responseType: "arraybuffer" })
      .then((res) => {
        const data = new Uint8Array(res.data);
        const workbook = new Excel.Workbook();
        return workbook.xlsx.load(data);
      })
      .then(async (workbook) => {
        //各シートにデータ出力
        //タイムライン
        setVisitList(workbook, commonData.visitList, commonData.customerList, commonData.staffList,commonData.scheduleList);
        //利用者情報
        setCustomerList(workbook, commonData.customerList);
        //スタッフ情報
        setStaffList(workbook, commonData.staffList);
        //未割当リスト
        setUnassinedList(workbook, commonData.visitList, commonData.customerList);
        
        //ダウンロード
        const uint8Array = await workbook.xlsx.writeBuffer();
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).replace(/\//g, '');

        const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `訪問計画_${formattedDate}.xlsx`;
        a.click();
        a.remove();

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * タイムラインシートに出力
 * @param workbook
 * @param customerList
 * @param visitList
 */
function setVisitList(workbook: Excel.Workbook,visitList: Models.Visit[],
  customerList: Models.Customer[],staffList: Models.Staff[],scheduleList: Models.Schedule[]){
  
  const sheet = workbook.getWorksheet(workbook.worksheets[0].name);
  //スタッフの数だけセルを追加
  addCell(sheet,staffList);

  const date = new Date();
  //今日の曜日を取得
  const dayOfWeek = date.getDay();
  //次の月曜日までの日数
  const daysUntilNextMonday = dayOfWeek === 1 ? 7 : (8 - dayOfWeek);
  //今日の日付に次の月曜日までの日数を加えて次の月曜日の日付を取得する
  const nextMonday = new Date(date);
  nextMonday.setDate(date.getDate() + daysUntilNextMonday);
  //シート名を来週の月曜日の日付に変更
  sheet.name = `${nextMonday.getMonth() + 1}.${nextMonday.getDate()}`;

  //日付と曜日を出力
  const dateRow = 1;
  let dateColumn = 2;
  for (let i = 0; i < 6; i++) {
    // 日付
    const currentDate = new Date(nextMonday);
    currentDate.setDate(currentDate.getDate() + i);
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    sheet.getCell(dateRow,dateColumn).value = `${month}月${day}日`;

    if (staffList.length > 5) {
      dateColumn += staffList.length - 1;
    } else {
      dateColumn += 4;
    }

    // 曜日
    const weekDay = Const.DAY_NAME_LIST[i];
    sheet.getCell(dateRow, dateColumn).value = weekDay;
    dateColumn++;
  }

  //タイムライン出力
  let columnIndex = 2;
  const staffRow = sheet.getRow(3);
  for (let day = 0; day <= 5; day++) {
    let staffListIndex = 0;
    for (let i = ExcelConst.TIMELINE_SHEET_START_COLUMN ;i < staffList.length + 2;i++) {
      // スタッフ
      const staff = staffList[staffListIndex];
      const staffName = staff.name.split(" ");
      staffRow.getCell(columnIndex).value = {
        richText: [
          { text: staffName[0], font: { name: "HGPｺﾞｼｯｸE", size: 16 }},
          { text: staff.code.toString(),font: { name: "HGPｺﾞｼｯｸE", size: 10 }}
        ]
      };

      // 対象の曜日と対象のスタッフからスケジュールを取得
      const targetScheduleList = scheduleList.filter((s) => s.day === day && s.staffCode == staff.code);
      //スケジュール出力
      if (targetScheduleList.length != 0) {
        targetScheduleList.forEach((targetSchedule) => {
          
          //スケジュール時間、内容
          const scheduleTimeRow = calculateRowIndex(targetSchedule.startTime);
          const startTime = targetSchedule.startTime.toString();
          const endTime = targetSchedule.endTime.toString();
          const scheduleTimeCell = sheet.getCell(scheduleTimeRow, columnIndex);
          scheduleTimeCell.value = startTime + "\n" + endTime + "\n" + targetSchedule.content;
          //終了時刻と開始時刻の差分
          const diffTime = targetSchedule.endTime.setFullYear(2023, 0, 1) - targetSchedule.startTime.setFullYear(2023, 0, 1);
          const diffMinutes = (diffTime / (1000 * 60 ));
          let diff = diffMinutes / 15; 
          if(diff == 0){
            diff = 1
          }
          //スケジュールの時間範囲をセル結合
          const margeCell = sheet.getCell(scheduleTimeRow + diff - 1, columnIndex);
          sheet.mergeCells(scheduleTimeCell.address, margeCell.address);
        });
      }

      // 対象の曜日と対象のスタッフから利用者を取得
      const targetVisitList = visitList.filter((v) => v.day === day && v.staffCode == staff.code);
      //訪問先出力
      if (targetVisitList.length != 0) {
        targetVisitList.forEach((targetVisit) => {
          
          //訪問時間
          const timeRow = calculateRowIndex(targetVisit.startTime);
          const startTime = targetVisit.startTime.toString();
          const endTime = targetVisit.endTime.toString();
          const timeCell = sheet.getCell(timeRow, columnIndex);
          timeCell.value = startTime + "\n" + endTime;

          // 利用者名
          const findCode = customerList.find((c) => c.code === targetVisit.customerCode);
          const name = findCode.name.replace(/ /g, "\n");
          const customerNameCell = sheet.getCell(timeRow + 1, columnIndex);
          customerNameCell.value = {
            richText: [
              { text: name, font: { name: "HGPｺﾞｼｯｸE", size: 15 }},
              { text: "\n" + "ID" + targetVisit.customerCode.toString(), font: { name: "HGPｺﾞｼｯｸE", size: 12 }}
            ]
          }
          //一般訪問看護の場合は斜体
          if(targetVisit.isGeneralNursing){
            customerNameCell.value.richText[0].font.italic = true;
            customerNameCell.value.richText[1].font.italic = true;
          }
          //2人訪問のメイン担当の場合は下線
          if(targetVisit.isPrimaryStaff && targetVisit.partnerVisitId !== null){
            customerNameCell.value.richText[0].font.underline  = true;
            customerNameCell.value.richText[1].font.underline  = true;
          }

          // 時刻を強調
          timeCell.font = {...sheet.getCell(timeCell.address).font}
          timeCell.font.bold = targetVisit.isTimeHighlighted ? true : false;

          //セルの背景色
          const color = CommonUtil.rgbToString(findCode.color);
          timeCell.style = {
            ...(timeCell.style || {}),
            fill: {type: "pattern",pattern: "solid",fgColor: { argb: color }},
          };
          customerNameCell.style = timeCell.style
        });
      }
      columnIndex++;
      staffListIndex++;
    }
    if (staffList.length < 5) {
      const num = 5 - staffList.length;
      columnIndex += num;
    }
  }
}

/**
 * スタッフの数だけセルを追加する
 * @param sheet
 * @param staffList
 */
const addCell = (sheet: Excel.Worksheet,staffList: Models.Staff[]) => {
  let staffNum = staffList.length;
  const shiftColumnIndexNum = staffNum - 5;
  if(staffNum <= 5){
    staffNum = 5;
  }else{
    //セル追加
    //F列（右に太罫線）のスタイルを指定の列にコピーし、F列のスタイルを消す
    const fColumn = 6 ; //F列
    sheet.eachRow({ includeEmpty: true }, (row) => {
      row.getCell(fColumn + shiftColumnIndexNum).style = row.getCell(fColumn).style;
      row.getCell(fColumn).style = null;
      //列幅のコピー
      sheet.getColumn(fColumn + shiftColumnIndexNum).width = sheet.getColumn(fColumn).width;
    });
    //セル挿入
    //E列のスタイルをコピーし、指定の列にスタイルを適用する
    const eColumn = 5; //E列
    sheet.eachRow({ includeEmpty: true }, (row) => {
      for (let i = 1; i < shiftColumnIndexNum + 1; i++) {
        row.getCell(eColumn + i).style = row.getCell(eColumn).style;
        //列幅のコピー
        sheet.getColumn(eColumn + i).width = sheet.getColumn(eColumn).width;
      }
    });
  }
  //セル結合
  sheet.mergeCells(1, 2, 1, staffNum);
  sheet.mergeCells(2, 2, 2, staffNum + 1);

   //結合したセルの線
  sheet.getCell('B2').border = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'medium' },
    left: { style: 'medium' }
  };

  //月曜日の表をコピーして、土曜日まで繰り返す
  for (let i = 1; i <= 5; i++) {
    const copyColumnIndex = staffNum * i;
    for (let row = 1; row <= sheet.rowCount; row++) {
      for (let column = 2; column <= staffNum + 1; column++) {
        const copyCell = sheet.getCell(row, column);
        const newCell = sheet.getCell(row, copyColumnIndex + column);
        newCell.style = copyCell.style;
        //列幅のコピー
        const columnWidth = sheet.getColumn(column).width;
        sheet.getColumn(copyColumnIndex + column).width = columnWidth;
      }
    }
    //セル結合
    sheet.mergeCells(1, copyColumnIndex + 2, 1, copyColumnIndex + staffNum);
    sheet.mergeCells(2, copyColumnIndex + 2, 2, copyColumnIndex + staffNum + 1);
    //1ぺージに収める
    sheet.pageSetup.fitToPage = true;
  }
}

/**
 * 行インデックスを計算する
 * @param date
 * @returns
 */
const calculateRowIndex = (date: Date): number => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  if (hours < 8) {
    return 5;
  }
  return 5 + ((hours - 8) * 4) + Math.floor(minutes / 15);
};

/**
 * 利用者情報シートに出力
 * @param workbook
 * @param customerList
 * @param visitList
 */
function setCustomerList(workbook: Excel.Workbook,customerList: Models.Customer[]) {
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.CUSTOMER);
  let index = 0;
  for (let rowi = ExcelConst.CUSTOMER_SHEET_START_ROW ; rowi < customerList.length + 2; rowi++) {
    const row = sheet.getRow(rowi);
    const customer = customerList[index];
    row.getCell(1).value = customer.code;
    row.getCell(2).value = customer.name;
    row.getCell(2).fill = { type: "pattern",pattern: "solid", fgColor: { argb: CommonUtil.rgbToString(customer.color) }};
    row.getCell(3).value = customer.address;
    row.getCell(4).value = customer.matchLevel;
    row.getCell(5).value = customer.matchLevelStr;
    row.getCell(6).value = customer.lat;
    row.getCell(7).value = customer.lng;
    row.getCell(8).value = customer.visitPerWeek;
    row.getCell(9).value = customer.requireTwoStaffs;
    row.getCell(10).value = ExcelConst.TWO_STAFF_MESSAGE[customer.requireTwoStaffs];
    row.getCell(11).value = customer.requireSex;
    row.getCell(12).value = ExcelConst.REQUIRE_SEX_MESSAGE[customer.requireSex];
    row.getCell(13).value = customer.specifiedTimes;
    if(customer.specifiedTimes == 1){
      row.getCell(14).value = '指定あり' ;
    }

    let requireTimeIndex = 0;
    for (let i = 0; i < 12; i+=2) {
      row.getCell(15 + i).value = createTime(customer.requireTime[requireTimeIndex].startTime);
      row.getCell(16 + i).value = createTime(customer.requireTime[requireTimeIndex].endTime);
      requireTimeIndex++;
    }
    row.getCell(27).value = customerList[index].others;
    index++;
  }
}

/**
 * スタッフ情報シートに出力
 * @param workbook
 * @param staffList
 */
function setStaffList(workbook: Excel.Workbook, staffList: Models.Staff[]) {
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.STAFF);
  let index = 0;
  for (let rowi = ExcelConst.STAFF_SHEET_START_ROW; rowi < staffList.length + 2 ; rowi++) {
    const row = sheet.getRow(rowi);
    const staff = staffList[index];
    row.getCell(1).value = staff.code;
    row.getCell(2).value = staff.name;
    row.getCell(3).value = staff.sex;
    row.getCell(4).value = ExcelConst.STAFF_SEX_NAME[staff.sex];
    row.getCell(5).value = staff.license;
    row.getCell(6).value = ExcelConst.STAFF_LICENSE_NAME[staff.license];

    let workTimeIndex = 0;
    for (let i = 0; i < 12; i+=2) {
      row.getCell(7 + i).value = createTime(staff.workTime[workTimeIndex].startTime);
      row.getCell(8 + i).value = createTime(staff.workTime[workTimeIndex].endTime);
      workTimeIndex++;
    }
    index++;
  }
}

/**
 * 未割当リストシートに出力
 * @param workbook
 * @param staffList
 */
function setUnassinedList(workbook: Excel.Workbook,visitList: Models.Visit[],customerList: Models.Customer[]) {
  const sheet = workbook.getWorksheet(ExcelConst.SheetNames.UNASSINGED);
  const unassinedList = visitList.filter((s) => s.day === -1);
  let index = 0;
  for (let rowi = ExcelConst.UNASSIGNED_SHEET_START_ROW; rowi < unassinedList.length + 2; rowi++) {
    const row = sheet.getRow(rowi);
    const code = unassinedList[index].customerCode;
    const name = customerList.find((s) => s.code === code).name;
    row.getCell(1).value = code;
    row.getCell(2).value = name;
    index++;
  }
}

/**
 * stringの時刻を返す
 * @param date
 * @returns
 */
const createTime = (date: Date): string | null => {
  if (date) {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else {
    return null;
  }
};
