import './PlanTable.css';
import * as Models from '../Models.tsx';
import {Menu, MenuItem} from '@mui/material';
import * as React from 'react';
import Draggable from 'react-draggable';
import {PublicParams, PublicCaches} from '../forms/MainForm.tsx';
import * as Const from '../Const.tsx';
import { CommonUtil } from '../CommonUtil.tsx';
import '../DateExtensions.tsx';
import {detectAlert} from './PlanTable.tsx'

/**
 * ドラッグ可能な顧客ブロックのコンポーネント
 * @param props.visit 訪問情報
 * @param props.setvisit visitのsetter
 * @param props.customer 利用者情報
 * @param props.unassignedIds 訪問計画全体の割当不能リスト(枠外にドラッグしたときに使用)
 * @param props.setUnassignedIds 訪問計画全体の割当不能リスト(枠外にドラッグしたときに使用)
 * @param props.index commonData.vivitListの中で何番目の訪問情報か？
 * @param props.key key:一意キー(なくても動くけど、つけることでパフォーマンスが向上する)
 * @param props.position ブロック左上端のXY位置 表の左上が(0,0)
 * @param props.multipleDraggingIds 複数選択中の訪問先(自分自身含む。ドラッグ移動中以外は空)
 * @param props.setMultipleDraggingIds multipleDraggingIdsのsetter
 * @returns 
 */
export function DraggableVisit(
    props : {visit : Models.Visit, setVisit : (visit: Models.Visit, index: number) => void, customer : Models.Customer,
    unassignedIds : number[], setUnassignedIds : React.Dispatch<React.SetStateAction<number[]>>, index : number, key : number, position:{x: number, y: number}
    multipleDraggingIds: number[], 
    setMultipleDraggingIds: React.Dispatch<React.SetStateAction<number[]>>}){
  
    //共通データ
    const commonData = React.useContext(PublicParams);
    const cacheData = React.useContext(PublicCaches);
  
    const nodeRef = React.useRef(null); 
    const rowHeight = Const.LAYOUT.TIMELINE_CELL_HEIGHT; //セル1マス分の高さ
    const colWidth = Const.LAYOUT.TIMELINE_CELL_WIDTH;   //セル1マス分の幅
    const dayBorder = 1;
    const startDay = 0; //月曜始まり
    const startHour = Const.TIMELINE_START_HOUR; //タイムライン開始時刻(例 8時)
    const endHour = Const.TIMELINE_END_HOUR;    //タイムライン終了時刻(例 19時)
  
    const dayWidth = colWidth * commonData.staffList.length + dayBorder; //1日分の幅
  
    /////////////////////////////////////////////
    // ドラッグイベント
    /////////////////////////////////////////////
  
    const [dragging, setDragging] = React.useState(false); //ドラッグ中か
    const [position, setPosition] = React.useState(props.position); //現在のDraggableブロックの座標(ドラッグの度に値が更新される)
  
    React.useEffect(()=>{
     //visitの変更があるたびに訪問先ブロックの位置を更新する
     setPosition(props.position);
    },[props.setVisit])
  
    const [alertDepVisits, setAlertDepVisits] = React.useState([]); //ドラッグ後に警告更新する必要がある訪問先ブロック
    //ドラッグ前のprops.visitの状態
    const [prevVisitState, setPrevVisitState] = React.useState(null);
    const [prevVisitPosition, setPrevVisitPosition] = React.useState(null);
  
    //ドラッグ開始時のイベントハンドラ
    const handleStart = (e, obj)=>{
      //訪問先ブロックの警告(アラート・ワーニング)を検出する
      //(ドラッグ後に警告を更新する必要があるブロックを調べるため)
      const alertSet : Models.AlertSet = detectAlert(props, commonData);
      setAlertDepVisits(alertSet.dependencyVisitList);
  
      //ドラッグ前の状態
      setPrevVisitState({ ...props.visit });
      setPrevVisitPosition({ ...props.position });
    }
  
    //ドラッグ時のイベントハンドラ
    const handleDrag = (e, obj) => {
      // Ctrlキーが押されている間はドラッグできないようにする
      const ctrlPressed = e.ctrlKey || e.metaKey;
      if (!ctrlPressed) {
        const { x, y } = obj;
        if(commonData.activeVisitIds.length >= 2 && !dragging){
          const ids = commonData.activeVisitIds.map( a => a.id).filter( i => i != props.visit.id);
          props.setMultipleDraggingIds([props.visit.id, ...ids]); //ドラッグしているブロックを先頭にする
        }else{
          setPosition({ x, y });
        }
        setDragging(true);
      } else {
        return;
      }
    }
  
    //ドラッグ終了時のイベントハンドラ 
    const handleStop = (e, obj)=>{
      //クリックの場合
      if(!dragging){
        //コントロールキーの配置
        const ctrlPressed = e.ctrlKey || e.metaKey;
  
        if(!ctrlPressed){
          //複数選択している場合は単一選択にする
          if(commonData.activeVisitIds.length >= 2){
            commonData.setActiveVisitIds([{ type: Const.ActiveBlockTypes.VISIT, id: props.visit.id}]);
          }
        }
        return;
      }
  
      //ドラッグ解除
      setDragging(false);
  
      let fittedX;
      let fittedY;
  
      if(obj.y >= 0){
        //移動後の時間などを格納する変数
        let newStartTime = props.visit.startTime;
        let newArrivalTime = props.visit.arrivalTime;
        let newDay = props.visit.day;
        let newStaffCode = props.visit.staffCode;
        let newEndTime = props.visit.endTime; 
        let accessoriesEndTime; //同時に選択された訪問先も含めたendtime
  
        //座標からセル位置を計算
        const x_ = obj.x + colWidth /2; //ブロックの中心のX座標
        const day = Math.floor(x_ / dayWidth);   
        const xInDay = x_ % dayWidth;
        const staff = xInDay < dayWidth - dayBorder ?  Math.floor(xInDay / colWidth) : commonData.staffList.length  - 1; 
        const row = Math.floor(obj.y / rowHeight);
  
        //セル位置から時刻を計算
        const startTimeH = Math.floor(row / 4) + startHour
        const startTimeM = (row % 4) * 15;
        newStartTime = new Models.Time(('0' + startTimeH ).slice( -2 ) + ":" + ('0' + startTimeM ).slice( -2 ));
  
        //セル位置から曜日とスタッフを計算
        newDay = day + startDay;
        newStaffCode= commonData.staffList[staff].code;
  
        //(タイムラインで)1つ上の訪問先を取得
        const earlierVisitList = commonData.visitList
                                .filter( v => v.id != props.visit.id && v.day == newDay && v.staffCode ==  newStaffCode && v.startTime <= newStartTime);
        const earlierScheduleList = commonData.scheduleList
                                .filter( s => s.day == newDay && s.staffCode ==  newStaffCode && s.startTime <= newStartTime);
        const earlierList:Models.VisitBase[] = [...earlierVisitList, ...earlierScheduleList].sort( (v,w) => w.startTime.getTime() - v.startTime.getTime());
        const previousVisit = earlierList.length > 0 ? earlierList[0] : null;
  
        //(タイムラインで)選択されていない1つ下の訪問先を取得
        let laterVisitList = commonData.visitList
                                .filter( v =>v.id != props.visit.id && v.day == newDay && v.staffCode ==  newStaffCode && v.startTime > newStartTime);
        const idsOfActive = new Set(commonData.activeVisitIds.map(v => v.id))
        laterVisitList = laterVisitList.filter(v => !idsOfActive.has(v.id))
        const laterScheduleList = commonData.scheduleList
                                .filter( v => v.day == newDay && v.staffCode ==  newStaffCode && v.startTime > newStartTime);
        const laterList:Models.VisitBase[] = [...laterVisitList, ...laterScheduleList].sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
        const nextVisit = laterList.length > 0 ? laterList[0] : null;

        //ブロック位置の自動調整
        const autoPositioning = async function(){
          //1つ上の訪問先から移動時間、1つ下の訪問先までの移動時間を取得する
          //(1つ上、1つ下の訪問先がない場合は0分)
          const {travelMinutesAbove, travelMinutesBelow} = await CommonUtil.fetchTravelMinutes(previousVisit, props.visit, nextVisit, commonData, cacheData);
          
          //ブロックをずらすことができるか
          let canMove = true;
  
          //////////////////////////////////////
          // Above
          // 自分自身の訪問先ブロックを下にずらす
          //////////////////////////////////////

          //「1つ上の訪問先の終了時刻＋移動時間」を計算
          let calculatedStartTime : Date;
          if(previousVisit == null){
              //1つ上の訪問先がない場合はマウスアップした時刻にする
              calculatedStartTime = new Date(newStartTime);             
          }else{
              //1つ上の訪問先の終了時刻＋移動時間
              calculatedStartTime = new Date(previousVisit.endTime);
              calculatedStartTime.addMinutes(travelMinutesAbove);
          }
  
          //「ドラッグでマウスアップした時刻」と「1つ上の訪問先の終了時刻＋移動時間」を比較
          let carryUpMinutes_A = 0; //ずらす時間(分) //AはAbove(上)の頭文字
          let shiftY_A =0; //ずらす位置Y(px)
          if(newStartTime < calculatedStartTime){ 
              //「1つ上の訪問先＋移動時間の時刻」の方が遅い場合は、その分ブロックを下にずらす
              carryUpMinutes_A = (calculatedStartTime.getTime() - newStartTime.getTime()) / (60*1000);
              newStartTime.addMinutes(carryUpMinutes_A);
              //セルの位置計算
              shiftY_A = Math.floor(carryUpMinutes_A * rowHeight / 15); //15で割れるはずだが念のためfloorしておく

              //到着時刻=開始時刻とする
              newArrivalTime = Models.Time.createTime(newStartTime);
          }

          //開始時刻から終了時刻を計算
          newEndTime = Models.Time.createTime(newStartTime);
          newEndTime.addMinutes(Const.DEFAULT_STAY_MINUTES);
          accessoriesEndTime = Models.Time.createTime(newEndTime);

          //////////////////////////////////////
          // Accessories
          // 複数ドラッグ時に一緒に選択したブロックを配置
          //////////////////////////////////////
          let accessories =[];
          const accessoryTimes : Models.TimeWindow[] = []; //accessoriesの各訪問先の開始終了時刻格納用
          if(props.multipleDraggingIds != null){
            //終了時刻計算用
            const currentTime = Models.Time.createTime(accessoriesEndTime);
            //付属ブロックを取得
            accessories = commonData.visitList.filter( v => props.multipleDraggingIds.includes(v.id) && v.id != props.visit.id);
            //付属ブロックに未割当の訪問先を含め訪問先が２つ以上あると、未割当から移動した人のstartTimeがnullのまま
            //startTime昇順にする
            accessories.sort((a, b) => {
              // aまたはbのstartTimeがnullの場合の処理
              if (a.startTime === null && b.startTime === null) {
                return 0; // どちらもnullなら順序を変更しない
              } else if (a.startTime === null) {
                return 1; // aがnullでbがnullでない場合はaを後に
              } else if (b.startTime === null) {
                return -1; // bがnullでaがnullでない場合はbを後に
              }
            
              // 両方のstartTimeがnullでない場合の比較
              return a.startTime.getTime() - b.startTime.getTime();
            });

            //複数選択の中に2人訪問がいるかどうかチェック
            const twoStaffList = [...accessories, props.visit].filter( v => v.partnerVisitId != null);

            if(twoStaffList.length > 0){
              if (!confirm("複数訪問を同時に移動するときは、2人訪問はすべて1人訪問になります。よろしいですか？")) {
                canMove = false;
                accessories.length=0;
              }
            }

            for(let idx=0; idx < accessories.length; idx++){  
              //移動時間を取得
              const preVisit =  accessories[idx - 1] || props.visit; //idxが1つ前のaccessory。1つ前がなければ選択中の訪問先
              const result = await CommonUtil.fetchTravelMinutes(preVisit, accessories[idx], null, commonData, cacheData);
              const moveMinutesFromPrevious = result.travelMinutesAbove;

              //時刻をセット
              currentTime.addMinutes(moveMinutesFromPrevious); //移動時間
              const sTime = Models.Time.createTime(currentTime);  //開始
              currentTime.addMinutes(Const.DEFAULT_STAY_MINUTES); //滞在時間
              const eTime = Models.Time.createTime(currentTime);  //終了
              const timeWindow: Models.TimeWindow = {
                startTime: sTime,
                endTime: eTime,
              };
              accessoryTimes.push(timeWindow);
            }

            accessoriesEndTime = currentTime;
          }
  
          //タイムラインの終端をはみ出さないかチェック
          if(obj.x >= dayWidth * Const.WORK_DAY_NUM - (colWidth / 2)){
            canMove = false;
          }
          if( endHour < accessoriesEndTime.getHours() + accessoriesEndTime.getMinutes() / 60){
              canMove = false;
          }

          //////////////////////////////////////
          // Below
          // 後続の訪問先ブロックを下にずらす
          //////////////////////////////////////
  
          //「訪問先の終了時刻＋移動時間」を計算
          let calculatedNextTime : Date;
          if(nextVisit != null){
              //訪問先の終了時刻＋移動時間
              calculatedNextTime = new Date(accessoriesEndTime);
              calculatedNextTime.addMinutes(travelMinutesBelow);
          }
  
          //「訪問先の終了時刻＋移動時間」と「元々設置してある1つ下の訪問先の開始時刻」を比較
          let carryUpMinutes_B = 0; //ずらす時間(分) //BはBelow(下)の頭文字
          if(nextVisit != null && nextVisit.startTime < calculatedNextTime ){ 
            if(props.visit.partnerVisitId !== null && !props.visit.isPrimaryStaff){
              carryUpMinutes_B = 0
            }else{
              //「訪問先の終了時刻＋移動時間」の方が遅い場合は、その分ブロックを下にずらす
              carryUpMinutes_B = (calculatedNextTime.getTime() - nextVisit.startTime.getTime()) / (60*1000);
            }
          }
          
          //タイムラインの終端をはみ出さないかチェック
          if(nextVisit != null){
              const lastVisit = laterList[laterList.length - 1];
              const calculatedLastTime = new Date(lastVisit.startTime);
              const stayTime = lastVisit.endTime.getTime() - lastVisit.startTime.getTime();
              calculatedLastTime.addMinutes((stayTime  / (60*1000)) + carryUpMinutes_B);
              if( endHour < calculatedLastTime.getHours() + calculatedLastTime.getMinutes() / 60){
              canMove = false;
              }
          }
  
          //移動先の下に2人訪問がいた場合のチェック
          if(nextVisit != null && canMove){
            //2人訪問のブロックをselect
            const laterTwoStaffList = laterVisitList
            .filter( v => v.partnerVisitId != null)
            .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
  
            for(let i=0; i< laterTwoStaffList.length; i++){
              //2人訪問の相方を取得
              const partnerVisit = commonData.visitList.find(v => v.id == laterTwoStaffList[i].partnerVisitId);
              //相方の下にいる訪問先を取得
              const partnerLaterVisitList = commonData.visitList
                  .filter( v => v.id != partnerVisit.id && v.day == partnerVisit.day && v.staffCode == partnerVisit.staffCode && v.startTime >= partnerVisit.startTime)
                  .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
              const partnerLaterScheduleList = commonData.scheduleList
                  .filter( s => s.id != partnerVisit.id && s.day == partnerVisit.day && s.staffCode == partnerVisit.staffCode && s.startTime >= partnerVisit.startTime)
                  .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
    
              const partnerLaterList = [...partnerLaterVisitList,...partnerLaterScheduleList];
              partnerLaterList.sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());    

              //相方の下に訪問先がいる場合、後続の訪問時間に影響が出なければOK
              if(partnerLaterList.length > 0){
                const {travelMinutesBelow} = await CommonUtil.fetchTravelMinutes(null, partnerVisit, partnerLaterList[0], commonData, cacheData);
                const partnerNewTime = new Date(partnerVisit.endTime);
                partnerNewTime.addMinutes(travelMinutesBelow);
                partnerNewTime.addMinutes(carryUpMinutes_B);
                if(partnerLaterList[0].startTime < partnerNewTime){
                  canMove = false;
                  break;
                }
              }
            }
          }

          //////////////////////////////////////
          // 2人訪問を動かすときのチェック
          //////////////////////////////////////

          //2人訪問(メイン)を動かすときのチェック
          if(accessories.length == 0 && props.visit.partnerVisitId != null && props.visit.isPrimaryStaff && canMove){
            //別の日または別のスタッフに移動するときは確認メッセージを出す
            if(prevVisitState.day !== newDay|| prevVisitState.staffCode !== newStaffCode){
              if(!confirm('2人訪問を1人訪問に変更しますが、よろしいですか？')){
                canMove = false;
              }
            }
          }

          //2人訪問(サブ)を動かすときのチェック
          if(accessories.length == 0 && props.visit.partnerVisitId !== null && !props.visit.isPrimaryStaff && canMove){
            const primaryVisit = commonData.visitList.find(v => v.id == props.visit.partnerVisitId);
            
            //メインと同じ曜日か
            if(newDay !== primaryVisit.day) canMove = false;
            
            //メインとは別のスタッフか
            if(newStaffCode == primaryVisit.staffCode) canMove = false;

            //メインと同じ時間帯に配置できるかどうか調べる
            if(canMove){
              //移動先の下にいる訪問先を取得
              const targetVisitList = commonData.visitList
              .filter( v => v.day == newDay && v.staffCode ==  newStaffCode && v.startTime >= primaryVisit.startTime)
              .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());

              const targetScheduleList = commonData.scheduleList
              .filter( v => v.day == newDay && v.staffCode ==  newStaffCode && v.startTime >= primaryVisit.startTime)
              .sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());

              const targetLaterList = [...targetVisitList,...targetScheduleList];
              targetLaterList.sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());

              const subVisit : Models.TimeWindow = {
                startTime: primaryVisit.startTime,
                endTime: primaryVisit.endTime
              };
              const isOverlapTime = CommonUtil.isOverlapTime(commonData.visitList.filter( v => v.day === newDay && v.staffCode === newStaffCode),subVisit);

              //移動先の下に訪問先がいる場合、後続の訪問時間に影響が出なければOK
              if(targetLaterList.length > 0){
                const {travelMinutesBelow} = await CommonUtil.fetchTravelMinutes(null, props.visit, targetLaterList[0], commonData, cacheData);
                const targetNewTime = new Date(primaryVisit.endTime);
                targetNewTime.addMinutes(travelMinutesBelow);
                if(targetLaterList[0].startTime < targetNewTime){
                  canMove = false;
                }
              }
              //移動先のスタッフに訪問先がいる場合、メインと同じ時間帯の訪問先がいなければOK
              //移動先の上に訪問先がいる場合、移動時間を考慮して影響がでなければOK
              if(isOverlapTime || primaryVisit.startTime < calculatedStartTime){
                canMove = false;
              }
            }
          }
  
          //動かす先の座標
          let targetX: number, targetY: number;
  
          //ブロックをずらせる場合
          if(canMove){
              //今回の移動で影響があるコースを調べる(影響があるコースは経路情報をリセットする)
              const dependencyCourse: {day : number, staffCode: number}[] =[];
              dependencyCourse.push({day: prevVisitState.day, staffCode:prevVisitState.staffCode}); //移動前
              dependencyCourse.push({day: newDay, staffCode:newStaffCode}); //移動後

              //開始時刻、終了時刻にセット
              //(commonData.visitListにセットするが、setter関数は後で通す)
              const current = commonData.visitList.find(v => v.id == props.visit.id);
              current.arrivalTime = newArrivalTime;
              current.startTime = newStartTime;
              current.endTime = newEndTime;
              for(let i =0; i < laterList.length; i++){
                laterList[i].arrivalTime?.addMinutes(carryUpMinutes_B);
                laterList[i].startTime.addMinutes(carryUpMinutes_B);
                laterList[i].endTime.addMinutes(carryUpMinutes_B);
                //相方
                if("partnerVisitId" in laterList[i]){
                  const partner = commonData.visitList.find(v => v.id == (laterList[i] as Models.Visit).partnerVisitId);
                  if(partner != undefined){
                    partner.startTime = laterList[i].startTime;
                    partner.endTime = laterList[i].endTime;
                    //相方の到着時刻計算
                    const earlierVisitList = commonData.visitList
                                .filter( v => v.id != partner.id && v.day == partner.day && v.staffCode ==  partner.staffCode && v.startTime <= partner.startTime );
                    const earlierScheduleList = commonData.scheduleList
                                .filter( s => s.day == partner.day && s.staffCode ==  partner.staffCode && s.startTime <= partner.startTime);
                    const earlierList:Models.VisitBase[] = [...earlierVisitList, ...earlierScheduleList].sort( (v,w) => w.startTime.getTime() - v.startTime.getTime());
                    const previousVisit = earlierList.length > 0 ? earlierList[0] : null; 
                    if(previousVisit == null){
                      //1つ前のブロックが存在しない場合、開始時刻が到着時刻
                      partner.arrivalTime = Models.Time.createTime(partner.startTime);
                    }else {
                      //1つ前のブロックがある場合、1つ前の終了時刻+移動時間 が到着時刻
                      const {travelMinutesAbove} = await CommonUtil.fetchTravelMinutes(previousVisit, partner, null, commonData, cacheData);
                      partner.arrivalTime  = Models.Time.createTime(previousVisit.endTime);
                      partner.arrivalTime.addMinutes(travelMinutesAbove);
                    }

                    dependencyCourse.push({day: partner.day, staffCode:partner.staffCode});
                  }
                }
              } 
              //複数選択
              for(let i=0; i < accessories.length; i++){
                accessories[i].arrivalTime = accessoryTimes[i].startTime;
                accessories[i].startTime = accessoryTimes[i].startTime;
                accessories[i].endTime = accessoryTimes[i].endTime;
                dependencyCourse.push({day: accessories[i].day, staffCode:accessories[i].staffCode});
              }

              //曜日スタッフをpropsにセット
              props.visit.day = newDay;
              props.visit.staffCode = newStaffCode;
              props.setUnassignedIds(props.unassignedIds.filter(x => x !== props.visit.unassinedId ));
              props.visit.unassinedId = null;
              //複数選択
              for(let i=0; i < accessories.length; i++){
                accessories[i].day = newDay;
                accessories[i].staffCode = newStaffCode;
                props.setUnassignedIds(props.unassignedIds.filter(x => x !== accessories[i].unassinedId ));
                accessories[i].unassinedId = null;
              }
   
              //2人訪問の処理(複数選択と単体選択で挙動が異なる)
              if(accessories.length > 0){
                //◆複数移動の場合
                //2人訪問はすべて1人訪問にする

                //まず、重複がない2人訪問の訪問先リストを作る
                //2人訪問のメインとサブ(あるいはサブとサブ)を双方選択している場合、idが若い方だけを残す
                let twoStaffVisitsDistinct:Models.Visit[] = [...accessories, props.visit].filter( v => v.partnerVisitId != null); 
                twoStaffVisitsDistinct.sort((a,b) => {
                  if(a.id == props.visit.id) return 1;
                  if(b.id == props.visit.id) return -1;
                  return (a.id - b.id)});
                const dupIds=[];
                for(let i=0; i<twoStaffVisitsDistinct.length; i++){
                  for(let j=i+1; j<twoStaffVisitsDistinct.length; j++){
                    if(twoStaffVisitsDistinct[i].partnerVisitId == twoStaffVisitsDistinct[j].id){
                      dupIds.push(twoStaffVisitsDistinct[j].id);
                    }
                  }
                }
                twoStaffVisitsDistinct = twoStaffVisitsDistinct.filter(v => !dupIds.includes(v.id));

                //2人訪問の訪問先は更新リストに、その相方は削除リストに入れる
                const updVisitIds:number[] = twoStaffVisitsDistinct.map( v =>v.id);
                const delVisitIds:number[] = twoStaffVisitsDistinct.map( v=> v.partnerVisitId);

                commonData.visitList = commonData.visitList.map( v=> {
                  if(updVisitIds.includes(v.id)){
                    //2人訪問(プロパティ変更);
                    props.visit.partnerVisitId = null;
                    props.visit.isPrimaryStaff = false;
                    props.setVisit(props.visit, props.index);
                    return {...v, partnerVisitId: null, isPrimaryStaff: false};
                  }else if(delVisitIds.includes(v.id)){
                    //2人訪問の相方(削除)
                    dependencyCourse.push({day: v.day, staffCode: v.staffCode});
                    return; //undefinedでupdateされる
                  }else{
                    //それ以外(そのまま)
                    return v;
                  }
                });
                commonData.visitList = commonData.visitList.filter( v => v !== undefined);
                CommonUtil.updateAlert(commonData);
              }else{
                //◆単体移動の場合
                //同一日同一スタッフ内での移動の時はメインとサブが同じ時間になるように調整。それ以外の移動は1人にする。

                //2人訪問(メイン)のとき
                const partner = commonData.visitList.find(v => v.id === prevVisitState.partnerVisitId); //相方。いない場合はundefined
                if(props.visit.partnerVisitId !== null && props.visit.isPrimaryStaff && partner != null){
                  // ２人訪問を(メイン)を動かした時のサブの一つ上の訪問先を取得（subsPreviousVisit）
                  const subsEarlierVisitList = commonData.visitList
                                          .filter(v => v.day == partner.day && v.staffCode == partner.staffCode && v.startTime <= newStartTime && v.id !== partner.id);
                  const subsEarlierScheduleList = commonData.scheduleList
                                          .filter( s => s.day == partner.day && s.staffCode ==  partner.staffCode && s.startTime <= newStartTime);
                  const subsEarlierList:Models.VisitBase[] = [...subsEarlierVisitList, ...subsEarlierScheduleList].sort( (v,w) => w.startTime.getTime() - v.startTime.getTime());
                  const subsPreviousVisit = subsEarlierList.length > 0 ? subsEarlierList[0] : null;

                  // ２人訪問を(メイン)を動かした時のサブの一つ下の訪問先を取得（subsNextVisit）
                  const subsBelowVisits = commonData.visitList
                                          .filter(v => v.day == partner.day && v.staffCode == partner.staffCode && v.startTime > newStartTime && v.id !== partner.id);
                  const subsBelowSchedules = commonData.scheduleList
                                          .filter( v => v.day == partner.day && v.staffCode ==  partner.staffCode && v.startTime > newStartTime);
                  const subsLaterList:Models.VisitBase[] = [...subsBelowVisits, ...subsBelowSchedules].sort( (v,w) => v.startTime.getTime() - w.startTime.getTime());
                  const subsNextVisit = subsLaterList.length > 0 ? subsLaterList[0] : null;
                  
                  // 上下の訪問先と相方との移動時間取得
                  const travelMinutes = await CommonUtil.fetchTravelMinutes(subsPreviousVisit, partner, subsNextVisit, commonData, cacheData)

                  //相方を影響コースに追加
                  dependencyCourse.push({day: prevVisitState.day , staffCode: partner.staffCode});
                  if(prevVisitState.day !== props.visit.day || prevVisitState.staffCode !== props.visit.staffCode){
                    //2人訪問の移動を別の曜日、別スタッフで移動した場合
                    props.visit.partnerVisitId = null;
                    props.visit.isPrimaryStaff = false;
                    props.setVisit(props.visit, props.index);
                    //2人訪問の相方を削除
                    commonData.visitList = commonData.visitList.filter(v => v.id !== prevVisitState.partnerVisitId);
                    CommonUtil.updateAlert(commonData);
                  }else if(prevVisitState.day === props.visit.day && prevVisitState.staffCode === props.visit.staffCode){
                      //2人訪問の移動を同じ曜日、同じスタッフで移動した場合
                      commonData.visitList = commonData.visitList .map(v => {
                        if (v.id === props.visit.partnerVisitId) {
                          //「訪問先の終了時刻＋移動時間」を計算
                          let calculatedNextTime : Date;
                          if(subsNextVisit != null){
                            //訪問先の終了時刻＋移動時間
                            calculatedNextTime = new Date(newEndTime);
                            calculatedNextTime.addMinutes(travelMinutes.travelMinutesBelow);
                          }
                          // 上部分に重なっている場合
                          if(subsNextVisit != null && subsNextVisit.startTime < calculatedNextTime){ 
                            alert("ドロップ先にブロックが存在するためドロップできません。")
                            canMove = false;
                            return v;
                          }
                          //「1つ上の訪問先の終了時刻＋移動時間」を計算
                          let calculatedStartTime : Date;
                          if(subsPreviousVisit != null){
                              //訪問先の終了時刻＋移動時間
                              calculatedStartTime = new Date(subsPreviousVisit.endTime);
                              calculatedStartTime.addMinutes(travelMinutes.travelMinutesAbove);
                          }
                          // 下部分に重なっている場合
                          if(subsPreviousVisit != null && newStartTime < calculatedStartTime){ 
                            alert("ドロップ先にブロックが存在するためドロップできません。")
                            canMove = false;
                            return v;
                          }
                          return { ...v,
                            arrivalTime: Models.Time.createTime(props.visit.arrivalTime), 
                            startTime: Models.Time.createTime(props.visit.startTime), 
                            endTime: Models.Time.createTime(props.visit.endTime) }
                        }else {
                            return v;
                        }
                      });
                      CommonUtil.updateAlert(commonData);
                  }
                }

                //2人訪問(サブ)のとき
                if(props.visit.partnerVisitId !== null && !props.visit.isPrimaryStaff){
                  //メインと同じ時間に配置する
                  const primaryVisit = commonData.visitList.find(v => v.id == props.visit.partnerVisitId);
                  current.arrivalTime = primaryVisit.startTime;
                  current.startTime = primaryVisit.startTime;
                  current.endTime = primaryVisit.endTime;
                  const newRow = (current.startTime.getHours() - startHour) * 4 + current.startTime.getMinutes() / 15;
                  shiftY_A = (newRow - row)* rowHeight;
                }
              }

              //警告アイコン判定
              refreshAlert();

              //ブロックをGridにフィットさせる
              fittedX = day * dayWidth + staff * colWidth;
              fittedY = row * rowHeight + shiftY_A; 

              //commonDataにset
              commonData.setVisitList([...commonData.visitList]);
              commonData.setScheduleList([...commonData.scheduleList]);

              //影響があるコースは経路情報を削除する
              commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));
  
              if(canMove){
                targetX = fittedX;
                targetY = fittedY;
              }else{
                //ずらせない場合はドラッグ前に戻す
                targetX = prevVisitPosition.x;
                targetY = prevVisitPosition.y;
                props.setVisit(prevVisitState,props.index);
              }
  
          }else{
              //ずらせない場合はドラッグ前に戻す
              targetX = prevVisitPosition.x;
              targetY = prevVisitPosition.y;
          }
  
          //10ミリ秒後にフィットさせる。
          //(直ちにフィットさせると,素早くドラッグしたときにreact-draggableがtranslateを上書きしてしまうので上手くいかない)
          //(いろいろ試したが、これしか方法が思いつかなかった)
          setTimeout(() => {
              obj.node.style.transform = `translate( ${targetX}px,  ${targetY}px)`;
              setPosition({x:targetX, y:targetY});
              props.setMultipleDraggingIds(null); //複数選択解除
          }, 10);
        }
        autoPositioning();

      }else{

        //ブロックをずらすことができるか
        let canMove = true;
        if(props.visit.partnerVisitId !== null){
          if(!confirm('2人訪問を1人訪問に変更しますが、よろしいですか？')){
            canMove = false;
          }
        }

        if(canMove){
          //枠外にドラッグしたときは割当不能スペースに移動
          const orginalUnassignedId = props.visit.unassinedId; //移動前の割当不能ID(タイムライン内からドラッグしてきたときはnull)
          const newUnassignedIds = props.unassignedIds.filter( id => id != orginalUnassignedId); //「移動前の割当不能ID」を除外した割当不能IDリスト

          //今回の移動で影響があるコースを調べる(影響があるコースは経路情報をリセットする)
          const dependencyCourse: {day : number, staffCode: number}[] =[];

          let cnt = CommonUtil.getMissingNumber(newUnassignedIds, 1);
          const addUnassinedList = commonData.activeVisitIds.map(item => item.id);
          const deleteList = [];
          commonData.setVisitList(
            commonData.visitList.map((v) => {
              if (addUnassinedList.includes(v.id) && v.unassinedId === null && !deleteList.includes(v.id)) {
                dependencyCourse.push({day: v.day, staffCode: v.staffCode}); 

                if(v.partnerVisitId !== null){
                  deleteList.push(v.partnerVisitId);
                }
                
                v.startTime = null;
                v.endTime = null;
                v.arrivalTime = null;
                v.day = -1;
                v.staffCode = -1;
                v.unassinedId = cnt;
                v.warning = [];
                v.alert = [];
                v.isPrimaryStaff =  false;
                v.partnerVisitId = null;
                v.isTimeHighlighted = false;
                
                newUnassignedIds.push(cnt)
                cnt = CommonUtil.getMissingNumber(newUnassignedIds, 1)
              }
              return v;
            })
          ); 
          props.setUnassignedIds(newUnassignedIds)
          
          for (let i = 0; i < deleteList.length; i++) {
            //削除する２人訪問の相方も影響のあるコースに追加する
            const partner = commonData.visitList.find( v => v.id === deleteList[i] )
            if(partner){
              dependencyCourse.push({day: partner.day, staffCode:partner.staffCode}); 
            }
          } 
          //影響があるコースは経路情報を削除する
          commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));
          //警告アイコン判定
          //(中でcommonDataを更新しているがsetはしていない)
          refreshAlert();
          commonData.setVisitList([...commonData.visitList.filter(v => !deleteList.includes(v.id))]);
        }
  
        if(canMove){
          //ブロックをGridにフィットさせる
          fittedX = Const.LAYOUT.UNASSIGNED_LEFT + colWidth * (props.visit.unassinedId - 1);
          fittedY = -Const.DRAGGABLE_VISIT_LAYER_TOP + 2; 
        }else{
          //ずらせない場合はドラッグ前に戻す
          fittedX = prevVisitPosition.x;
          fittedY = prevVisitPosition.y;
         }
        
        //10ミリ秒後にフィットさせる。
        //(直ちにフィットさせると,素早くドラッグしたときにreact-draggableがtranslateを上書きしてしまうので上手くいかない)
        //(いろいろ試したが、これしか方法が思いつかなかった)
        setTimeout(() => {
          obj.node.style.transform = `translate( ${fittedX}px,  ${fittedY}px)`;
          setPosition({x:fittedX, y:fittedY});
          props.setMultipleDraggingIds(null); //複数選択解除
        }, 10);
      }    
    }
  
    //訪問先をドラッグで移動した直後、画面の警告メッセージを更新する
    const refreshAlert = function(){
      //訪問先ブロックの警告(アラート・ワーニング)を検出する
      const alertSet : Models.AlertSet = detectAlert(props, commonData);
  
      //警告をセット
      props.visit.warning = alertSet.warningList;
      props.visit.alert = alertSet.alertList;
      const index = commonData.visitList.findIndex( v => v.id === props.visit.id);
      commonData.visitList[index] = props.visit;
  
      //影響がある訪問先ブロックの警告を再検査
      const mergedDepsList = [...alertDepVisits, ...alertSet.dependencyVisitList];
      mergedDepsList.forEach(visit => {
        const customer = commonData.customerList.find( c => c.code === visit.customerCode);
        if(customer != null){
          const alertSet_dep: Models.AlertSet = detectAlert({visit: visit, customer: customer}, commonData);
          //警告をセット
          visit.warning = alertSet_dep.warningList;
          visit.alert = alertSet_dep.alertList;
          const idx = commonData.visitList.findIndex( v => v.id === visit.id);
          commonData.visitList[idx] = visit;
        }
      });
    }
  
    /////////////////////////////////////////////
    // マウスダウンイベント
    /////////////////////////////////////////////
    const handleMouseDown = (e)=>{
      //コントロールキーの配置
      const ctrlPressed = e.ctrlKey || e.metaKey;
      const leftClick = e.button === 0;
      
      if (ctrlPressed && leftClick) {
        // Ctrlキーが押されている場合、配列に追加する
        const newVisit = { type: Const.ActiveBlockTypes.VISIT, id: props.visit.id , day: props.visit.day, staffCode: props.visit.staffCode };
  
        commonData.setActiveVisitIds(prevVisitIds => {
          //スケジュールは選択解除しておく
          prevVisitIds = prevVisitIds.filter(idObj => idObj.type != Const.ActiveBlockTypes.SCHEDULE);
  
          // 同じIDのデータが既に存在するかチェック
          const exists = prevVisitIds.some(idObj => idObj.id === newVisit.id);
        
          // 存在しない場合のみ追加
          if (!exists) {
            return [...prevVisitIds, newVisit];
          }else{
            return prevVisitIds.filter(idObj => idObj.id !== newVisit.id)
          }
        });
  
  
      } else {
        // Ctrlキーが押されていない場合、単一のIDとしてセットする（初期化）
        if(!commonData.activeVisitIds.some( a => a.type == Const.ActiveBlockTypes.VISIT && a.id == props.visit.id)){
          const tmp = { type: Const.ActiveBlockTypes.VISIT, id: props.visit.id, day: props.visit.day, staffCode: props.visit.staffCode }
          commonData.setActiveVisitIds([tmp]); 
        }
      }
    }
  
    /////////////////////////////////////////////
    // 右クリックメニュー
    /////////////////////////////////////////////
    const [contextMenu, setContextMenu] = React.useState<Models.ContextMenuPosition | null>(null);
  
    const handleContextMenuOpen = (e) => {
      e.preventDefault();
      if(contextMenu === null){
        setContextMenu({
          mouseX: e.clientX + 2,
          mouseY: e.clientY - 6,
        });
      }else{
        setContextMenu(null);
      }
    };
  
    const handleContextMenuClose = () => {
      setContextMenu(null);
    };
  
    const handleDeleteCustomer = () => {
      setContextMenu(null);
      if(confirm("利用者情報を削除します。よろしいですか？")){
        console.log(`利用者削除 customercode=${props.visit.customerCode}`);
        commonData.setActiveVisitIds([]);
        //利用者(customer)
        commonData.setCustomerList(commonData.customerList.filter(c => c.code != props.visit.customerCode));
        //訪問先(visit)
        commonData.setVisitList(commonData.visitList.filter(v => v.customerCode != props.visit.customerCode));
      }
    };
  
    //visitを削除
    const handleDeleteVisit = () => {
      setContextMenu(null);
      const deleteList = [];
      const count: { [key: number]: number } = {};
      for (let i = 0; i < commonData.activeVisitIds.length; i++) {
        const index = commonData.visitList.findIndex( v => v.id === commonData.activeVisitIds[i].id);
        if(commonData.visitList[index].unassinedId === null){
          alert('割当済みの訪問先は削除できません');
          return;
        }
        // 削除対象をdeleteListに追加
        deleteList.push(commonData.activeVisitIds[i].id);
        const customerCode = commonData.visitList[index].customerCode;
        count[customerCode] = (count[customerCode] || 0) + 1;
      }    
      if(confirm("訪問先を削除します。よろしいですか？")){
       //利用者の訪問頻度を減らす
        const updatedCustomerList = commonData.customerList.map(c => {
          //countオブジェクトがc.codeをキーとして持っているかどうか
          if (Object.prototype.hasOwnProperty.call(count, c.code)) {
            return { ...c, visitPerWeek: c.visitPerWeek - count[c.code] };
          } else {
            return c;
          }
        });
        commonData.setCustomerList(updatedCustomerList);
        commonData.setActiveVisitIds([]);
        commonData.setVisitList(commonData.visitList.filter(v => !deleteList.includes(v.id)));
        handleContextMenuClose();
      }
    };
  
    //選択した利用者のすべての訪問先を未割当にする
    const handleChangeAllUnassined = () => {
       //今回の移動で影響があるコースを調べる(影響があるコースは経路情報をリセットする)
       const dependencyCourse: {day : number, staffCode: number}[] =[];
      if (!confirm("選択した利用者のすべての訪問先を未割当にします。よろしいですか？")) {
        return;
      }
      const unassignedIds = props.unassignedIds;
      const deleteList = [];
      const addUnassinedList = [];
      for (let i = 0; i < commonData.activeVisitIds.length ; i++) {
        const customerCode = commonData.visitList.find( v => v.id === commonData.activeVisitIds[i].id).customerCode;
        if(!addUnassinedList.includes(customerCode)){
          addUnassinedList.push(customerCode)
        }
      } 
      const updatedVisitList = commonData.visitList.map(v => {
        if (addUnassinedList.includes(v.customerCode) && v.unassinedId === null && !deleteList.includes(v.id)) {
          dependencyCourse.push({day: v.day, staffCode: v.staffCode});
          const newUnassignedId = CommonUtil.getMissingNumber(unassignedIds, 1);
          props.setUnassignedIds([...unassignedIds, newUnassignedId]);
          unassignedIds.push(newUnassignedId);
          //２人訪問の場合は相方を削除
          if(v.partnerVisitId !== null){
            deleteList.push(v.partnerVisitId);
          }
          
          return {
            ...v, 
            day: -1, 
            staffCode: -1, 
            startTime: null, 
            endTime: null, 
            warning: [], 
            alert: [], 
            unassinedId: newUnassignedId,
            isPrimaryStaff: false,
            partnerVisitId: null }
        }else {
          return v;
        }
      });
      //commonDataの経路情報を削除
      for (let i = 0; i < deleteList.length; i++) {
        //削除する２人訪問の相方も影響のあるコースに追加する
        const partner = commonData.visitList.find( v => v.id === deleteList[i] )
        if(partner){
          dependencyCourse.push({day: partner.day, staffCode:partner.staffCode}); 
        }
      } 
      commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));
      commonData.setVisitList(updatedVisitList.filter(v => !deleteList.includes(v.id)));
      handleContextMenuClose();       
    }

    //訪問先を未割当にする
    const handleChangeUnassined = () => {
      //今回の移動で影響があるコースを調べる(影響があるコースは経路情報をリセットする)
      const dependencyCourse: {day : number, staffCode: number}[] =[];
      //複数選択中のすべての訪問先を未割当にする
      const addUnassinedList = commonData.activeVisitIds.map(item => item.id);
      const unassignedIds = props.unassignedIds;
      const deleteList = [];
      const updatedVisitList = commonData.visitList.map(v => {
        if (addUnassinedList.includes(v.id) && v.unassinedId === null && !deleteList.includes(v.id)) {
          dependencyCourse.push({day: v.day, staffCode: v.staffCode});
          //２人訪問の場合は相方を削除
          if(v.partnerVisitId !== null){
            deleteList.push(v.partnerVisitId);
          }
          const newUnassignedId = CommonUtil.getMissingNumber(unassignedIds, 1);
          props.setUnassignedIds([...unassignedIds, newUnassignedId]);
          unassignedIds.push(newUnassignedId);
          return {
            ...v, 
            day: -1, 
            staffCode: -1, 
            startTime: null, 
            endTime: null, 
            warning: [], 
            alert: [], 
            unassinedId: newUnassignedId,
            isPrimaryStaff: false,
            partnerVisitId: null }
        }else {
          return v;
        }
      });

      //commonDataの経路情報を削除
      for (let i = 0; i < deleteList.length; i++) {
        //削除する２人訪問の相方も影響のあるコースに追加する
        const partner = commonData.visitList.find( v => v.id === deleteList[i] )
        if(partner){
          dependencyCourse.push({day: partner.day, staffCode:partner.staffCode}); 
        }
      } 
      commonData.setDirectionsList(commonData.directionsList.filter(x => !dependencyCourse.some( c => c.day == x.day && c.staffCode == x.staffCode)));
      commonData.setVisitList(updatedVisitList.filter(v => !deleteList.includes(v.id)));
      props.setMultipleDraggingIds(null); //複数選択解除
      handleContextMenuClose();
    };
      
      //利用者情報修正
      const handleOpenCustomerDialog = () => {
        commonData.setDialogName(Const.DialogNames.CUSTOMER_DIALOG);
        handleContextMenuClose();
      };
  
      //2人訪問にする/1人訪問にする
      const handleChangeNumberOfVisitors = () => {
    
        if (props.visit.partnerVisitId === null) {
          let staffCode;
          const otherStaffList = commonData.staffList.filter(s => s.code !== props.visit.staffCode);
          otherStaffList.sort((a,b) => {
            if(a.license != b.license){
              //0:無資格 → 2:准看護師 → 1:看護師 の順
              const priority = [1,3,2];
              return priority[a.license] - priority[b.license];     
            }
        });
          
        let isStaffFound = false;
    
        for (const staff of otherStaffList) {
          const existingVisitList = commonData.visitList.filter(v =>
            v.day === props.visit.day &&
            v.staffCode === staff.code
          );
          const existingScheduleList = commonData.scheduleList.filter(s =>
            s.day === props.visit.day &&
            s.staffCode === staff.code
          );
    
          const newSchedule = {
            startTime: props.visit.startTime,
            endTime: props.visit.endTime
          };
    
          const isVisitOverlapTime = CommonUtil.isOverlapTime(existingVisitList, newSchedule);
          const isScheduleOverlapTime = CommonUtil.isOverlapTime(existingScheduleList, newSchedule);
    
          if (!isVisitOverlapTime && !isScheduleOverlapTime) {
            staffCode = staff.code;
            isStaffFound = true;
            break;
          }
        }
    
        if (!isStaffFound) {
          alert('スケジュールが空いているスタッフがいないため、２人訪問にできません。');
          return;
        }
  
        const updatedVisitList = commonData.visitList.map(v => {
          if (v.id === props.visit.id) {
            return { ...v,  partnerVisitId: commonData.visitList.slice(-1)[0].id + 1, isPrimaryStaff: true };
          } else {
            return v;
          }
        });
        
        const newVisit = {
          id: commonData.visitList.slice(-1)[0].id + 1,
          customerCode: props.visit.customerCode,
          day: props.visit.day,
          staffCode: staffCode,
          arrivalTime: props.visit.arrivalTime ? Models.Time.createTime(props.visit.arrivalTime) : null,
          startTime: Models.Time.createTime(props.visit.startTime),
          endTime: Models.Time.createTime(props.visit.endTime),
          warning: [],
          alert: [],
          partnerVisitId: props.visit.id,
          unassinedId: props.visit.unassinedId,
          isPrimaryStaff: false,
          isGeneralNursing: props.visit.isGeneralNursing,
          isTimeHighlighted: props.visit.isTimeHighlighted
        };
        updatedVisitList.push(newVisit);
        
        //commonDataの経路情報を削除
        commonData.setDirectionsList(commonData.directionsList.filter(x => !((x.day == newVisit.day && x.staffCode == newVisit.staffCode))));
        
        const newCommonData = {
          ...commonData,
          visitList: [...updatedVisitList]
        };
        
        //警告(新しく増えた方)
        const customer = commonData.customerList.find( c => c.code === newVisit.customerCode);
        const alertSet: Models.AlertSet = detectAlert({visit: newVisit, customer: customer}, newCommonData);
        newVisit.alert = alertSet.alertList;
        newVisit.warning = alertSet.warningList;

        //警告(元からあった方)
        const visitOrg = updatedVisitList.find( v => v.id == props.visit.id);
        const customerOrg = commonData.customerList.find( c => c.code === visitOrg.customerCode);
        const alertSetOrg: Models.AlertSet = detectAlert({visit: visitOrg, customer: customerOrg}, newCommonData);
        visitOrg.alert = alertSetOrg.alertList;
        visitOrg.warning = alertSetOrg.warningList;

        //commonDataの訪問先リストを更新
        commonData.setVisitList(updatedVisitList);

      } else {
        // 既に2人訪問の場合は、選んだブロックを削除
        const updatedVisitList = commonData.visitList.map(v =>
          v.id === props.visit.partnerVisitId ? { ...v, partnerVisitId: null, isPrimaryStaff: false } : v
        );
        const newCommonData = {
          ...commonData,
          visitList: [...updatedVisitList],
        };
        CommonUtil.updateAlert(newCommonData);
        //commonDataの経路情報を削除
        commonData.setDirectionsList(commonData.directionsList.filter(x => !((x.day == props.visit.day && x.staffCode == props.visit.staffCode))));
        //commonDataの訪問先リストを更新
        commonData.setVisitList(updatedVisitList.filter(v => v.id !== props.visit.id));
        commonData.setActiveVisitIds([]);
      }
      handleContextMenuClose();
    };
  
    
    //2人訪問のメイン担当者にする
    const handlePrimaryStaff = () => {
      const updatedVisitList = commonData.visitList.map(v => {
        if (v.id === props.visit.id) {
          return { ...v, isPrimaryStaff: !v.isPrimaryStaff };
        } else if (v.partnerVisitId === props.visit.id && v.isPrimaryStaff) {
          return { ...v, isPrimaryStaff: !v.isPrimaryStaff };
        } else {
          return v;
        }
      });
      const newCommonData = {
        ...commonData,
        visitList: [...updatedVisitList],
      };
      CommonUtil.updateAlert(newCommonData);
      commonData.setVisitList(updatedVisitList);
      handleContextMenuClose();
    };
  
    //一般訪問看護にする
    const handleGeneralNursing = () => {
      const updatedVisitList = commonData.visitList.map(v => {
        if (v.id === props.visit.id) {
          return { ...v, isGeneralNursing: !v.isGeneralNursing };
        } else if (v.partnerVisitId === props.visit.id) {
          return { ...v, isGeneralNursing: !v.isGeneralNursing };
        } else {
          return v;
        }
      });
      commonData.setVisitList(updatedVisitList);
      handleContextMenuClose();
    }
  
    //時刻を強調
    const handleHighlightTime = () => {
      const updatedVisitList = commonData.visitList.map(v => {
        if (v.id === props.visit.id) {
          return { ...v, isTimeHighlighted: !v.isTimeHighlighted };
        } else if (v.partnerVisitId === props.visit.id) {
          return { ...v, isTimeHighlighted: !v.isTimeHighlighted };
        }else {
          return v;
        }
      });
    
      commonData.setVisitList(updatedVisitList);
      handleContextMenuClose();
    };
  
    /////////////////////////////////////////////
    // 警告アイコン表示
    /////////////////////////////////////////////
    const [alertView, setAlertView] = React.useState<React.JSX.Element>();
    const [warinngView, setWarinngView] = React.useState<React.JSX.Element>();
  
    React.useEffect(()=>{
      setAlertView(
        props.visit.alert.length > 0 ? 
        <span className='alert'><span className='toolTip'>{props.visit.alert[0]}</span></span> : 
        <></>);
      setWarinngView(
        props.visit.warning.length > 0 ? 
        <span className='warning'><span className='toolTip'>{props.visit.warning[0]}</span></span> : 
        <></>);
      
    },[props.visit.alert, props.visit.warning])
  
    const isActive = commonData.activeVisitIds.some(idObj => 
      idObj.type === Const.ActiveBlockTypes.VISIT && props.visit.id === idObj.id);
  
    let nameColor = "transparent";
    if(props.customer.color != null && !isWhite(props.customer.color)){
      nameColor = `rgb(${props.customer.color[0]} ${props.customer.color[1]} ${props.customer.color[2]} / 75%)`
    }
  
    //白かどうか判定
    function isWhite(color : number[]){
        return color[0] == 255 && color[1] == 255 && color[2] == 255
    }
  
    //利用者の全訪問が未割当になっているかどうかを判定
    function isAllUnassined(){
      //この訪問ブロックが未割当ではない場合はfalse
      if(props.visit.unassinedId === undefined || props.visit.unassinedId === null){
        return false
      }
      //この訪問ブロックと同じ利用者
      const sameCustomerVisit = commonData.visitList.filter(v => v.customerCode == props.visit.customerCode);
      //いずれかの訪問が割当済の場合はfalse
      for(let i =0; i < sameCustomerVisit.length; i++){
        if(sameCustomerVisit[i].unassinedId === undefined || sameCustomerVisit[i].unassinedId === null){
          return false; 
        }
      }
      return true;
    }
    
    //利用者が未割当になっているかどうかを判定
    function isUnassined(){
      //この訪問ブロックが未割当ではない場合はfalse
      if(props.visit.unassinedId === undefined || props.visit.unassinedId === null){
        return false
      }
      return true;
    }
  
    //利用者が2人訪問であるかどうかを判定
    function isTwoStaffs(){
      //この訪問ブロックが2人訪問ではない場合はfalse
      if(props.visit.partnerVisitId === null){
        return false
      }
      return true;
    }
  
    return(
      <div onContextMenu={handleContextMenuOpen}>
        <Draggable
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop} 
          onMouseDown={handleMouseDown}
          position={position}
          bounds={{left:0, top:-Const.DRAGGABLE_VISIT_LAYER_TOP, right: dayWidth * Const.WORK_DAY_NUM - (colWidth / 2), bottom: rowHeight * 6 * 10}}
          nodeRef={nodeRef}
          >
            <div 
              ref={nodeRef} 
              className={"visit" + (dragging ? ' dragging-visit' : '') + (isActive ? ' active-visit' : '') 
                + (props.multipleDraggingIds != null ? ' multiple-dragging' : '')}
              >
                <span style={{fontSize:'x-small' , fontWeight: props.visit.isTimeHighlighted ? 'bold' : 'normal' }}>{(props.visit.startTime ? props.visit.startTime.toString() : '\u00A0')}</span>
                {alertView}{warinngView}
                <div 
                  className={props.visit.isGeneralNursing ? 'general-nursing-customer' : ''} 
                  style={{ lineHeight: '1em', backgroundColor: nameColor, textDecoration: props.visit.isPrimaryStaff && props.visit.partnerVisitId !== null ? 'underline' : ''}}>
                  {props.customer.name}
                  </div>
            </div>
        </Draggable>
        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
  
          {!isUnassined() ? <MenuItem onClick={handleChangeUnassined}>この訪問を未割当にする</MenuItem>: ''}
          <MenuItem onClick={handleChangeAllUnassined}>すべて未割当にする</MenuItem>
          <MenuItem onClick={handleOpenCustomerDialog}>修正</MenuItem>
          {!isUnassined() ?<MenuItem onClick={handleChangeNumberOfVisitors}>{isTwoStaffs() ? '1人訪問にする' : '2人訪問にする'}</MenuItem>: ''}
          {isTwoStaffs() ? <MenuItem onClick={handlePrimaryStaff}>{props.visit.isPrimaryStaff ? 'メイン担当を解除' : 'メイン担当にする'}</MenuItem> : ''}
          <MenuItem onClick={handleGeneralNursing}>{props.visit.isGeneralNursing ? '精神科訪問看護にする' : '一般訪問看護にする'}</MenuItem>
          {!isUnassined() ? <MenuItem onClick={handleHighlightTime}>{props.visit.isTimeHighlighted ? '時刻の強調を解除' : '時刻を強調'}</MenuItem> : ''}
          {isAllUnassined() ? <MenuItem onClick={handleDeleteCustomer}>この利用者を削除</MenuItem> : ''}
          {isUnassined() ? <MenuItem onClick={handleDeleteVisit}>この訪問先ブロックを削除</MenuItem> : ''}
        </Menu>
      </div>
    )
  }
