/////////////////////////////
// 定数・列挙型クラス
/////////////////////////////

/** ダイアログ名 */
export const DialogNames = {
    CUSTOMER_DIALOG: 1,
    SCHEDULE_DIALOG: 2,
    ADD_CUSTOMER_DIALOG: 3,
    ADD_SCHEDULE_DIALOG: 4,
} as const;
export type DialogNameType = typeof DialogNames[keyof typeof DialogNames];

/** Draggableブロックの背景色 */
export const BlockColors = {
    WHITE : "#ffffff",
    RED :"#ff0000",
    GREEN : "#00ff00",
    BLUE : "#0000ff",
    YELLOW : "#ffff00",
    CYAN : "#00ffff",
    PINK : "#ff00ff",
} as const;
export type BlockColorType = typeof BlockColors[keyof typeof BlockColors];

/** GoogleMapAPIキー(地図表示用) */
//export const GOOGLE_MAPS_API_KEY = "AIzaSyBbUwq9ECgHGX9CkDGVybtF8A1dhvywC1g"; //開発用
export const GOOGLE_MAPS_API_KEY = "AIzaSyAnymSHs-I96Ay-qz_uINaQkL5kyBNlvUM"; //本番用

/** GoogleMapAPIキー(ジオコーディング用)*/
//export const GOOGLE_MAPS_API_KEY_GEOCODING = "AIzaSyAImJmv8e2a4x7lqrwDLabxLsD2PIP1F-s"; //開発用
export const GOOGLE_MAPS_API_KEY_GEOCODING = "AIzaSyBEFdIRzItALPXgwH1CoJ1Gz2K458_7H4Q"; //本番用

/** 最初にMapを表示する時の設定 */
export const MAP_DEFAULT = {
    CENTER: {
        lat: 35.66777801513672,
        lng: 139.75502014160156 
    },
    ZOOM: 13,
    STYLES:[
        {
            featureType: "all",
            elementType: "all",
            stylers: [{ "saturation": -30 }],
        },
        {
            featureType: "transit",
            elementType: "labels",
            stylers: [{ "saturation": -80 }],
        },
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ "saturation": -80 }],
        }
    ] ,
    MAPID: "c2d8c54b16f76d94",
};

/**選択中のブロックの種類 */
export const ActiveBlockTypes = {
    NONE : -1,
    VISIT : 1,
    STAFF : 2,
    SCHEDULE : 3
} as const;
export type ActiveBlockTypeType = typeof ActiveBlockTypes[keyof typeof ActiveBlockTypes];

/** 1週間の勤務日数 */
export const WORK_DAY_NUM = 6;

/** 曜日名のリスト */
export const DAY_NAME_LIST = ["月", "火", "水", "木", "金", "土"];

/** タイムライン開始時刻(時) */
export const TIMELINE_START_HOUR = 8;

/** タイムライン終了時刻(時) */
export const TIMELINE_END_HOUR = 18;

/** 標準滞在時間(分) */
export const DEFAULT_STAY_MINUTES = 35;

/** 画面レイアウトに関する数値 */
export const LAYOUT = {
    HEADER_HEIGHT : 35, //ヘッダの高さ
    SPLIT_LEFT_PERCENTAGE : 80, //splitterで左右に分割したときの左の部分の割合(%)
    UNASSIGNED_LEFT : 50, //ドラッグ可能領域(未割当)の左端位置
    UNASSIGNED_HEIGHT: 55, //ドラッグ可能領域(未割当)の高さ
    DAY_HEDDER_HEIGHT : 25, //日ヘッダ(「月」とか「火」とかが書いてあるグレーのセル)の高さ
    DAY_ALERT_MARGIN_LEFT : 10, //日ヘッダの中の曜日と警告メッセージの間のスペースの広さ 
    STAFF_NAME_GRID_HEIGHT: 20, //職員名と訪問件数が書かれている領域の高さ
    STAFF_ALERT_GRID_HEIGHT : 60, //職員名下の警告メッセージが表示される領域の高さ

    TIMELINE_CELL_HEIGHT : 21,  //タイムラインの1セルあたりの高さ
    TIMELINE_CELL_WIDTH : 65,   //タイムラインの1セルあたりの幅
}

/** ドラッグ可能領域(タイムライン)の上端位置 */
export const DRAGGABLE_VISIT_LAYER_TOP 
    = LAYOUT.UNASSIGNED_HEIGHT + LAYOUT.DAY_HEDDER_HEIGHT + LAYOUT.STAFF_NAME_GRID_HEIGHT + LAYOUT.STAFF_ALERT_GRID_HEIGHT;

/** 1コースあたりの訪問件数がこの件数以上になったら、件数の表示色を変える */
export const COUNT_WARNING_NUM = 8;

/** 同一曜日に同じ座標の訪問先がこの件数以上になったら警告を表示する */
export const SAME_LOCATION_CHECK_NUM = 3;

/** 警告(アラート)メッセージ */
export const AlertMessages = {
    OVERLAP: "重複訪問",
    LICSENSE_VIOLATION: "資格違反",
    TWO_STAFFS_UNDESIRED: "訪問人数",
    NO_PRIMARY_STAFF: "主不在"
}
export type AlertMessageTypes = typeof AlertMessages[keyof typeof AlertMessages];

/** 警告(ワーニング)メッセージ */
export const WarningMessages = {
    SAME_LOCATION: "同一建物",
    TWO_STAFFS_UNDESIRED: "訪問人数",
    TIME_UNDESIRED: "希望時刻",
    SEX_UNDESIRED: "性別",
    OUT_OF_WORK_TIME: "勤務時刻",
}
export type WarningMessageTypes = typeof WarningMessages[keyof typeof WarningMessages];

/**
   * ダイアログのラジオボタンの色
   */
export const RADIO_STYLE_RED = {
    color: BlockColors.RED, 
    '& span' : {fontSize:'x-large'},
};
export const RADIO_STYLE_GREEN = {
    color: BlockColors.GREEN, 
    '& span' : {fontSize:'x-large'},
};
export const RADIO_STYLE_BLUE = {
    color: BlockColors.BLUE, 
    '& span' : {fontSize:'x-large'},
};
export const RADIO_STYLE_YELLOW = {
    color: BlockColors.YELLOW, 
    '& span' : {fontSize:'x-large'},
};
export const RADIO_STYLE_CYAN = {
    color: BlockColors.CYAN, 
    '& span' : {fontSize:'x-large'},
};
export const RADIO_STYLE_PINK = {
    color: BlockColors.PINK, 
    '& span' : {fontSize:'x-large'},
};

