import * as Models from './Models.tsx';
import {Time} from './Models.tsx';
import * as Const from './Const.tsx';

/////////////////////////////
// サンプルデータ
/////////////////////////////

//訪問データ
const sampleVisitList : Models.Visit[] = [
    {
        id : 1,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 1,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:15"),   //訪問開始時刻 ★
        endTime : new Time("09:50"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 2,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 2,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:20"),   //訪問開始時刻 ★
        endTime : new Time("10:55"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 3,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 3,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:00"),   //訪問開始時刻 ★
        endTime : new Time("09:35"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 4,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 4,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:40"),   //訪問開始時刻 ★
        endTime : new Time("10:15"),     //訪問終了時刻 
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 5,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 5,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:30"),   //訪問開始時刻 ★
        endTime : new Time("10:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null ,     //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 6,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 6,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 7,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 7,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("11:20"),   //訪問開始時刻 ★
        endTime : new Time("11:55"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 8,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 10,  //利用者コード ★
        day : 0,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("13:00"),   //訪問開始時刻 ★
        endTime : new Time("13:35"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 9,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 5,  //利用者コード ★
        day : 1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:30"),   //訪問開始時刻 ★
        endTime : new Time("10:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 10,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 9,  //利用者コード ★
        day : 1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 11,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : true,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 11,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 9,  //利用者コード ★
        day : 1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023005,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 10,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 12,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 1,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:15"),   //訪問開始時刻 ★
        endTime : new Time("09:50"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 14,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : true,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 13,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 2,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:20"),   //訪問開始時刻 ★
        endTime : new Time("10:55"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 14,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 1,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:15"),   //訪問開始時刻 ★
        endTime : new Time("09:50"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 12,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 15,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 5,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 16,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 3,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:00"),   //訪問開始時刻 ★
        endTime : new Time("09:35"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 17,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 4,  //利用者コード ★
        day : 2,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:40"),   //訪問開始時刻 ★
        endTime : new Time("10:15"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 18,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 5,  //利用者コード ★
        day : 3,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:30"),   //訪問開始時刻 ★
        endTime : new Time("10:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 19,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 9,  //利用者コード ★
        day : 3,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 20,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : true,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 20,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 9,  //利用者コード ★
        day : 3,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : 19,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 21,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 10,  //利用者コード ★
        day : 3,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023002,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("11:30"),   //訪問開始時刻 ★
        endTime : new Time("12:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 22,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 1,  //利用者コード ★
        day : 4,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023001,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:15"),   //訪問開始時刻 ★
        endTime : new Time("09:50"),     //訪問終了時刻 
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 23,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 3,  //利用者コード ★
        day : 4,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:00"),   //訪問開始時刻 ★
        endTime : new Time("09:35"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 24,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 4,  //利用者コード ★
        day : 4,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("09:40"),   //訪問開始時刻 ★
        endTime : new Time("10:15"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 25,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 6,  //利用者コード ★
        day : 4,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : 2023003,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : new Time("10:30"),   //訪問開始時刻 ★
        endTime : new Time("11:05"),     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : null,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 101,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 2,  //利用者コード ★
        day : -1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : -1,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : null,   //訪問開始時刻 ★
        endTime : null,     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : 1,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 102,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 5,  //利用者コード ★
        day : -1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : -1,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : null,   //訪問開始時刻 ★
        endTime : null,     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : 2,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
    {
        id : 103,  //訪問データごとに一意に割りあてられる番号 ★
        customerCode : 8,  //利用者コード ★
        day : -1,           //曜日 (0:月 1:火 2:水 …) ★
        staffCode : -1,     //職員コード ★
        arrivalTime : null, //到着時刻
        startTime : null,   //訪問開始時刻 ★
        endTime : null,     //訪問終了時刻 ★
        warning : [], //ワーニング
        alert : [],   //アラート
        partnerVisitId : null,  //2人訪問のとき、相方のVisitのid ★
        unassinedId : 3,      //割当不能のとき、何番目の割当不能か ★
        isPrimaryStaff : false,  //2人訪問のメイン担当かどうか ★
        isGeneralNursing: false, //一般訪問看護かどうか ★
        isTimeHighlighted: false, //時刻を強調するかどうか ★
    },
]

//利用者データ
const sampleCustomerList : Models.Customer[] = [
    {
        code : -1,  //利用者コード ★
        name : "東京事業所",  //利用者名 ★
        address : "東京都港区新橋２丁目１２−１６ 明和ビル 4F" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.66763256266648,   //緯度 ★
        lng : 139.75476931266786,   //経度 ★
        color : null,
        visitPerWeek : 0,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null}
        ] ,
        others : "",           //その他希望 ★
    },
    {
        code : 1,  //利用者コード ★
        name : "佐藤 太郎",  //利用者名 ★
        address : "東京都千代田区丸の内１丁目９−１" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.6797088,   //緯度 ★
        lng : 139.7676876,   //経度 ★
        color : [255, 255, 255],
        visitPerWeek : 3,     //訪問頻度 ★
        requireTwoStaffs : 50, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 1,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: new Time("09:00"), endTime: new Time("10:00")},
            {startTime: null, endTime: null},
            {startTime: new Time("09:00"), endTime: new Time("10:00")},
            {startTime: null, endTime: null},
            {startTime: new Time("09:00"), endTime: new Time("10:00")},
            {startTime: new Time("09:00"), endTime: new Time("10:00")}
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 2,  //利用者コード ★
        name : "鈴木 二郎",  //利用者名 ★
        address : "東京都千代田区有楽町１丁目１−４ 東京ミッドタウン日比谷" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.67425090604172,   //緯度 ★
        lng : 139.75937184159062,   //経度 ★
        color : [255, 255, 255],
        visitPerWeek : 3,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 3,  //利用者コード ★
        name : "高橋 三郎",  //利用者名 ★
        address : "東京都千代田区内幸町２丁目１−１" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.67108265015543,   //緯度 ★
        lng : 139.7528349331204,   //経度 ★
        color : [255, 0, 0],
        visitPerWeek : 3,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 1,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 4,  //利用者コード ★
        name : "田中 四郎",  //利用者名 ★
        address : "東京都千代田区内幸町２丁目１−１" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.67108265015543,   //緯度 ★
        lng : 139.7528349331204,   //経度 ★
        color : [255, 0, 0],
        visitPerWeek : 3,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 5,  //利用者コード ★
        name : "伊藤 五郎",  //利用者名 ★
        address : "東京都港区西新橋１丁目３−１" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.66920929913542,   //緯度 ★
        lng : 139.75485947801366,   //経度 ★
        color : [255, 255, 255],
        visitPerWeek : 5,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 6,  //利用者コード ★
        name : "渡辺 六郎",  //利用者名 ★
        address : "東京都港区赤坂７丁目１−１６ オーク赤坂ビル" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.674523120959975,   //緯度 ★
        lng : 139.72996034898594 ,   //経度 ★
        color : [255, 255, 255],
        visitPerWeek : 2,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 1,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: new Time("10:50"), endTime: new Time("11:10")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: new Time("10:50"), endTime: new Time("11:10")},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 7,  //利用者コード ★
        name : "山本 七郎",  //利用者名 ★
        address : "東京都千代田区大手町１丁目３−２" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.688486726675364,   //緯度 ★
        lng : 139.76340430450102,   //経度 ★
        color : [102, 255, 102],
        visitPerWeek : 1,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 1,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: new Time("10:00"), endTime: new Time("23:59")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 8,  //利用者コード ★
        name : "中村 八郎",  //利用者名 ★
        address : "東京都千代田区三番町１−１６ 三番町ホテルビル 1F" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.689560666406564,   //緯度 ★
        lng : 139.74521569097467,   //経度 ★
        color : [255, 255, 255],
        visitPerWeek : 1,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 1,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: new Time("00:00"), endTime: new Time("08:30")},
            {startTime: new Time("00:00"), endTime: new Time("08:30")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 9,  //利用者コード ★
        name : "小林 九郎",  //利用者名 ★
        address : "東京都千代田区内幸町２丁目１−１" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.67108265015543,   //緯度 ★
        lng : 139.7528349331204,   //経度 ★
        color: [255, 0, 0],
        visitPerWeek : 2,     //訪問頻度 ★
        requireTwoStaffs : 100, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 1,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: null},
            {startTime: new Time("08:30"), endTime: new Time("18:00")},
            {startTime: null, endTime: null},
            {startTime: new Time("08:30"), endTime: new Time("18:00")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
    {
        code : 10,  //利用者コード ★
        name : "加藤 十郎",  //利用者名 ★
        address : "東京都千代田区大手町１丁目３−２" ,
        matchLevel : 8,
        matchLevelStr : "[高]子番",
        lat : 35.688486726675364,   //緯度 ★
        lng : 139.76340430450102,   //経度 ★
        color : [102, 255, 102],
        visitPerWeek : 2,     //訪問頻度 ★
        requireTwoStaffs : 0, //2人訪問の希望度合い ★
        requireSex : 0,        //希望職員性別(0:どちらでもよい 1:女性希望 2:男性希望) ★
        specifiedTimes : 0,
        requireTime : [        //希望時刻(月～土) ★
            {startTime: null, endTime: new Time("23:59")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
            {startTime: null, endTime: new Time("23:59")},
            {startTime: null, endTime: null},
            {startTime: null, endTime: null},
        ] ,
        others : "特になし",           //その他希望 ★
    },
]

//職員データ
const sampleStaffList : Models.Staff[] = [
    {
        code : 2023001,   //社員コード ★
        name : "山本 翔平",    //社員名 ★
        sex : 2,     //性別(1:女性 2:男性 0:その他) ★
        license : 1, //資格(1:正看護師 2:准看護師 0:無資格/その他) ★
        workTime : [        //勤務時刻(月～土) ★
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: null, endTime: null},
        ]   
    } ,
    {
        code : 2023002,   //社員コード ★
        name : "湯川 太郎",    //社員名 ★
        sex : 2,     //性別(1:女性 2:男性 0:その他) ★
        license : 2, //資格(1:正看護師 2:准看護師 0:無資格/その他) ★
        workTime : [        //勤務時刻(月～土) ★
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: null, endTime: null},
        ]   
    } ,
    {
        code : 2023003,   //社員コード ★
        name : "新井 智恵子",    //社員名 ★
        sex : 1,     //性別(1:女性 2:男性 0:その他) ★
        license : 1, //資格(1:正看護師 2:准看護師 0:無資格/その他) ★
        workTime : [        //勤務時刻(月～土) ★
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: new Time("08:00"), endTime: new Time("16:30")},
            {startTime: null, endTime: null},
        ]   
    } ,
    {
        code : 2023005,   //社員コード ★
        name : "宮下 英樹",    //社員名 ★
        sex : 2,     //性別(1:女性 2:男性 0:その他) ★
        license : 0, //資格(1:正看護師 2:准看護師 0:無資格/その他) ★
        workTime : [        //勤務時刻(月～土) ★
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
            {startTime: null, endTime: null},
            {startTime: new Time("09:00"), endTime: new Time("17:30")},
        ]   
    } 
]

/**
 * サンプルデータの取得
 * @returns サンプルデータ
 */
export function getSampleCommonData(){
    //各項目はディープコピーして呼出元に渡す。(一度json文字列にしてから再度オブジェクトに戻している)
    const newSampleCommonData : Models.CommonData ={
        //選択中のブロック
        activeVisitIds : [],
        setActiveVisitIds : () => {},
        //ダイアログが開かれているかどうか
        dialogName : null,
        setDialogName : () =>{},
        //訪問リスト
        visitList : JSON.parse(JSON.stringify(sampleVisitList), Models.reverser),
        setVisitList :  () => {},
        //利用者リスト
        customerList : JSON.parse(JSON.stringify(sampleCustomerList), Models.reverser),
        setCustomerList : () => {},
        //職員リスト
        staffList : JSON.parse(JSON.stringify(sampleStaffList), Models.reverser),
        setStaffList : () => {},
        //経路データ
        directionsList : [],
        setDirectionsList: () => {},
        //職員スケジュールリスト
        scheduleList : [],
        setScheduleList : () => {},
    }
    return newSampleCommonData;
}

