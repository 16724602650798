export const TIMELINE_SHEET_START_COLUMN = 2 ;
export const TIMELINE_SHEET_START_ROW = 5 ;
export const CUSTOMER_SHEET_START_ROW = 2 ;
export const STAFF_SHEET_START_ROW = 2 ;
export const UNASSIGNED_SHEET_START_ROW = 2 ;

export const DATE = "2023/1/1 ";

export const SheetNames = {
    CUSTOMER : "利用者情報",
    STAFF : "スタッフ情報",
    UNASSINGED : "未割当リスト",
} as const;
export type SheetNamesType = typeof SheetNames[keyof typeof SheetNames];

export const STAFF_SEX_NAME = ['その他','女','男'];
export const STAFF_LICENSE_NAME = ['その他','看護師','准看護師','理学療法士'];
export const TWO_STAFF_MESSAGE = {
    0 : 'どちらでもよい',
    50 : 'できれば2人',
    100 : '2人',
};
export const REQUIRE_SEX_MESSAGE = ['どちらでもよい','女性希望','男性希望'];
