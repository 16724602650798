import * as React from 'react'; 
import * as Models from '../Models.tsx';
import * as ImportExcelFile from '../forms/ImportExcelFile.tsx';

/**
 * ファイルドロップ受付画面
 * @param setShowingForm Appに表示させる画面のsetter
 * @returns
 */
export function DropForm({setCommonData}){

  //ファイルdrop時
  function dropHandler(ev) {
    ev.preventDefault(); // 既定の動作を防ぐ (ファイルが開かれないようにする)
    const dropZone = document.getElementById("drop-zone") as HTMLElement;
    dropZone.style.background = "#ffffff";

    const file = ev.dataTransfer.files[0];
    const fetchExcelFile: Promise<{staffList: Models.Staff[];customerList: Models.Customer[];visitList: Models.Visit[];scheduleList: Models.Schedule[]}> = ImportExcelFile.readExcelFile(file);
    fetchExcelFile.then(onResolved).catch(onError);
  }

  //読み込みデータをCommonDataにセット
  function onResolved({ staffList, customerList, visitList, scheduleList }) {
    const testCommonData: Models.CommonData = {
      //選択中のブロック
      activeVisitIds: [] as Models.ActiveVisitIdentity[], //アクティブブロックID,
      setActiveVisitIds: () => {},
      //利用者情報ダイアログが開かれているかどうか
      dialogName : null,
      setDialogName : () =>{},
      //訪問リスト
      visitList: JSON.parse(JSON.stringify(visitList), Models.reverser),
      setVisitList: () => {},
      //利用者リスト
      customerList: JSON.parse(JSON.stringify(customerList), Models.reverser),
      setCustomerList: () => {},
      //職員リスト
      staffList: JSON.parse(JSON.stringify(staffList), Models.reverser),
      setStaffList: () => {},
      //経路リスト
      directionsList:[],
      setDirectionsList: () => {},
      //職員スケジュールリスト
      scheduleList : JSON.parse(JSON.stringify(scheduleList), Models.reverser),
      setScheduleList :() => {}
    };
    return setCommonData(testCommonData);
  }

   //エラーログ出力
  function onError(error) {
    console.log(error);
  }

  //ファイルdragOver時
  function dragOverHandler(ev) {
    ev.preventDefault(); // 既定の動作を防ぐ (これがないとdropイベントが発火しない)
  }

  //ファイルdragEnter時
  function dragEnterHandler() {
    const dropZone = document.getElementById("drop-zone") as HTMLElement;
    dropZone.style.background = "#ffcccc";
  }

  //ファイルdragLeave時
  function dragLeaveHandler() {
    const dropZone = document.getElementById("drop-zone") as HTMLElement;
    dropZone.style.background = "#ffffff";
  }

  return (
    <div id="body-wrapper">
      <div
        id="drop-zone"
        style={{
          border: "1px dotted",
          padding: "30px",
          height: "calc(100% - 60px)",
        }}
        onDrop={dropHandler} onDragOver={dragOverHandler} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler}
      >
        <p>訪問計画Excelファイルを枠内にドラッグ&ドロップもしくは</p>
        <input type="file" name="file" id="file-input"></input>
      </div>
    </div>
  );
}
