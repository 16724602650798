import * as React from 'react';
import {Wrapper} from "@googlemaps/react-wrapper";
import {PublicParams} from '../forms/MainForm.tsx';
import * as Models from '../Models.tsx';
import * as Const from '../Const.tsx';
import {Direction} from './Direction.tsx';

//マーカー画像
import MarkerImageActive from '../images/markers/personmarker-icon-2x-red-fixed.png';
import MarkerImageSemiActive from '../images/markers/marker-icon-2x-red-fixed.png';
import MarkerImageSemiActive2 from '../images/markers/marker-icon-2x-blue.png';
import MarkerImage from '../images/markers/marker-icon-2x-black-opacity-50.png';
import MarkerImageHome from '../images/markers/home-icon.png'

/**
 * Mapコンポーネント(MapBoxをラップしているコンポーネント)
 * @returns 
 */
export function Map(){
  //共通データ
  const commonData = React.useContext(PublicParams);

  //Maps JavaScript API の読み込みエラーの処理を行う render プロパティ
  const render = (status) => {
      return <h1>{status}</h1>;
  };

  //選択中の訪問先を調べる
  let activeVisit : Models.Visit = null;
  if(commonData.activeVisitIds.length > 0 && commonData.activeVisitIds[0].type === Const.ActiveBlockTypes.VISIT){
    activeVisit = commonData.visitList.find((p) => (p.id === commonData.activeVisitIds[0].id));
  }
  const activeCustomerCode = activeVisit == null ? -2: activeVisit.customerCode ;

  //選択中の訪問先と同じ日の訪問先を調べる
  const sameDayVisitList : Models.Visit[] = activeVisit == null ? [] : commonData.visitList.filter((p) => p.day === activeVisit?.day && p.unassinedId == null);
  const sameDayVisitListCode : number[] = activeVisit == null ? [] : sameDayVisitList.map((v) => v.customerCode); 

  //選択中の訪問先と同じコースの訪問先を調べる
  const sameCourseVisit = activeVisit == null ? [] : sameDayVisitList.filter((p) => p.staffCode === activeVisit?.staffCode);
  const sameCourseVisitCodes = activeVisit == null ? [] : sameCourseVisit.map((v) => v.customerCode); 

  //ActiveCustomerに応じて各customerのiconの種類を決める
  //customerListIconとcommonData.customerListは同じ並び順
  const customerListIcon = [];
  for(let i=0; i < commonData.customerList.length; i++){
    let icon = MarkerImage; //別の日
    if(commonData.customerList[i].code === activeCustomerCode){
      icon = MarkerImageActive; //アクティブ利用者
    }else if(sameCourseVisitCodes.includes(commonData.customerList[i].code)){
      icon = MarkerImageSemiActive; //同じコース
    }else if(sameDayVisitListCode.includes(commonData.customerList[i].code)){
      icon = MarkerImageSemiActive2; //同じ日
    }else if(commonData.customerList[i].code == -1){
      icon = MarkerImageHome; //事業所
    }
    customerListIcon.push(icon);
  }

  //ここに現在表示中のコースの経路情報(GoogleMapから帰ってきた結果データ)を入れる
  const [currentDirection, setCurrentDirection] = React.useState<google.maps.DirectionsResult>(null);

  //選択状態に合わせてcurrentDirectionを切り替える
  React.useEffect(()=>{
    //訪問先以外はnull
    if(commonData.activeVisitIds.length == 0 || commonData.activeVisitIds[0].type != Const.ActiveBlockTypes.VISIT){
      setCurrentDirection(null);
      return;
    }

    //選択中のコースを調べる
    const currentVisit = commonData.visitList.find( p => p.id === commonData.activeVisitIds[0].id);
    if(!currentVisit){
      return;
    }
    const currentDay = currentVisit.day;
    const currentStaffCode = currentVisit.staffCode;
    const findDirections = commonData.directionsList?.find( x => x.day == currentDay && x.staffCode == currentStaffCode)?.directions;

    //選択中のコースの経路情報をセット
    setCurrentDirection(findDirections);
  
  },[commonData.activeVisitIds]);

  //Directionコンポーネントの処理が完了したときの処理
  const onDirectionFinished = (dlist: Models.DirectionsList[])=>{

    //Directionコンポーネントから返ってきたDirectionを元に各コースの到着時刻を計算する
    for(let di =0; di < dlist.length; di++){
      const hitCourse = commonData.visitList?.filter( x => x.day == dlist[di].day && x.staffCode == dlist[di].staffCode)
      .sort((a, b) => a.startTime > b.startTime ? 1 : -1);

      if(hitCourse.length == 0){
        return;
      }

      //1人コースの場合はnullになる
      if(dlist[di].directions == null){
        hitCourse[0].arrivalTime = Models.Time.createTime(hitCourse[0].startTime);
        continue;
      }

      //念のため(通常はここは通らないはず)
      if(hitCourse.length -1 != dlist[di].directions.routes[0].legs.length){
        alert(`到着時刻の計算に失敗しました。次の社員コードの社員の到着時刻が正しくない可能性があります。 [${dlist[di].staffCode}]`);
        continue;
      }

      //各訪問先の到着時刻を計算する
      hitCourse[0].arrivalTime = Models.Time.createTime( hitCourse[0].startTime);
      for(let i=1; i<hitCourse.length; i++){
        const newArrivalTime = Models.Time.createTime(hitCourse[i-1].endTime);
        let travelMin = dlist[di].directions.routes[0].legs[i-1].duration.value /60;
        travelMin = Math.floor(travelMin / 5) * 5 + 5; //5分単位に丸める
        newArrivalTime.addMinutes(travelMin); //移動時間を加算
        hitCourse[i].arrivalTime = newArrivalTime;
      }
    }

    //【注釈】
    //Direction.tsxのuseffectによる無限ループ防止のため
    //commonData.setVisitList([...commonData.visitList]);
    //とはしていない。
    //commonData.visitListの参照メモリは変えずに、commonData.visitList[x].arrivalTimeの値を変更している

    //アクティブブロックをセットしなおす
    commonData.setActiveVisitIds([...commonData.activeVisitIds]);

    //setActiveVisitIdsをしたことで,このソースファイルの上にある
    //useEffect(()=>{・・・},[commonData.activeVisitIds])
    //が実行されるので、地図の経路が最新化される
  }

  const mapCenter = commonData.customerList.length > 0 ? {lat:commonData.customerList[0].lat, lng:commonData.customerList[0].lng} : null;

  return(
      <Wrapper apiKey={Const.GOOGLE_MAPS_API_KEY} render={render}>
        <MapBox center={mapCenter}>
          {commonData.customerList.map((customer, i) => (             
              <Marker 
                position={{lat: customer.lat, lng: customer.lng}}
                icon={{
                  url: customerListIcon[i],
                  scaledSize: (
                    customerListIcon[i] === MarkerImageHome ? 
                    {width: 32, height: 32, equals: null} :
                    customerListIcon[i] === MarkerImageActive ?
                    {width: 27, height: 45, equals: null} :
                    {width: 21, height: 35, equals: null} 
                  )
                  }}
                zIndex={
                  customer.code === activeCustomerCode
                    ? 100
                    : sameCourseVisitCodes.includes(customer.code)
                    ? 90
                    : 0
                }
                key={i}
                >
              </Marker>
          ))}
          <Direction 
            callback={onDirectionFinished}
            />
          {currentDirection !== null && activeVisit?.unassinedId === null && (             
            <DirectionsRenderer
             directions= {currentDirection}
             markerOptions = {{visible :false}}
             polylineOptions = {{strokeColor : "#ff0000"}}
             preserveViewport = {true}
            />
          )}
        </MapBox>
      </Wrapper>
  )
}

/**
 * MapBoxコンポーネント(Mapの中身)
 * @param props <MapBox></MapBox>の中に入っているコンポーネントを渡すための変数
 * @returns 
 */
function MapBox(props: { children: object, center:{lat:number, lng:number} }){
  const mapBox = React.useRef(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    //初回表示時の処理
    if (mapBox.current && !map) { 
      const option:google.maps.MapOptions = {
        center: props.center ?? Const.MAP_DEFAULT.CENTER,
        zoom: Const.MAP_DEFAULT.ZOOM,
        styles : Const.MAP_DEFAULT.STYLES,
      };

      const map = new window.google.maps.Map(mapBox.current, option);
      setMap(map);

      const div = document.createElement('div');
      div.style.background = 'white';
      div.style.padding = '2px';
      div.style.marginTop = '10px';
      div.style.borderRadius = '10px';
      div.style.display = 'flex'; 
      
      const content = `
        <div style="display: flex; align-items: center;">
          <img src="${MarkerImageActive}" style="width: 10px; height: auto;">
          <span style="font-size: 0.8em; margin-right: 3px; margin-left: 3px;">選択中の利用者</span>
          <img src="${MarkerImageSemiActive}" style="width: 10px; height: auto;">
          <span style="font-size: 0.8em; margin-right: 3px; margin-left: 3px;">同コースの利用者</span>
        </div>
        <div style="display: flex; align-items: center;">
          <img src="${MarkerImageSemiActive2}" style="width: 10px; height: auto;">
          <span style="font-size: 0.8em; margin-right: 3px; margin-left: 3px;">同日の別スタッフの訪問先</span>
          <img src="${MarkerImage}" style="width: 10px; height: auto;">
          <span style="font-size: 0.8em; margin-right: 3px; margin-left: 3px;">その他</span>
        </div>
      `;

      const contentDiv = document.createElement('div');
      contentDiv.innerHTML = content;

      const closebutton = document.createElement('button');
      closebutton.innerHTML = '×'; 
      closebutton.style.margin = '5px'; 
      closebutton.style.padding = '5px 10px';
      closebutton.style.border = 'none';
      closebutton.style.backgroundColor = '#d0d0d0'; 
      closebutton.style.color = 'white';
      closebutton.style.borderRadius = '5px';

      div.appendChild(contentDiv);
      div.appendChild(closebutton);

      let isOpen = true;

      closebutton.addEventListener("click", () => {
        if (isOpen) {
          contentDiv.innerHTML = '';
          closebutton.innerHTML =`
            <div>
            <img src="${MarkerImage}" style="width: 10px; height: auto;">
            </div>
          `;
        } else {
          contentDiv.innerHTML = content;
          closebutton.innerHTML = '×'; 
        }
        isOpen = !isOpen; 
      });
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(div);
    }
    
  }, [mapBox, map]);

  return (
    <>
      <div id="map-box" style={{height: '100%'}} ref={mapBox} />
      {/* ▼おまじない。これを入れる必要がある、と公式ドキュメントに書いてあった。*/}
      {React.Children.map(props.children, (child) => {
          if (React.isValidElement(child)) {
            //@ts-expect-error:set the map prop on the child component
            return React.cloneElement(child, { map });
          }
        })
      }
      {/* ▲おまじないここまで */}
    </>
    
  );
};

/**
 * マーカーコンポーネント
 * @param option
 * @returns 
 */
function Marker(option: google.maps.MarkerOptions){
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(()=>{
    //マーカー生成時の処理
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    
    //マーカーアンマウント時の処理
    return () => {
      if (marker) {
        marker.setMap(null); //マーカー削除
      }
    };
  },[marker])

  //マーカー生成時、またはoption(座標等)が変更された時の処理
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(option);
    }
  }, [marker, option]);

  return null;
}

/**
 * 経路線描画レンダラーコンポーネント
 * @param option 
 * @returns 
 */
function DirectionsRenderer(option: google.maps.DirectionsRendererOptions){
  const [renderer, setRenderer] = React.useState<google.maps.DirectionsRenderer>();

  React.useEffect(()=>{
    //レンダラー生成時の処理
    if (!renderer) {
      setRenderer(new window.google.maps.DirectionsRenderer());
    }
    
    //レンダラーアンマウント時の処理
    return () => {
      if (renderer) {
        renderer.setMap(null); //レンダラー削除
      }
    };
  },[renderer])

  //レンダラー生成時、またはoption(経路情報等)が変更された時の処理
  React.useEffect(() => {
    if (renderer && option.directions != null && option.directions != undefined) {
      renderer.setOptions(option);
    }
  }, [renderer]);

  React.useEffect(() => {
    if (renderer && option.directions != null && option.directions != undefined) {
      renderer.setOptions(option);
    }
  }, [option.directions]);

  return null;
}